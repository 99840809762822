import classNames from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';
import Button from './../Button';
import './_Styles.scss';

export const Modal = ({
  large,
  small,
  className,
  animationClass = 'fade-in-top fast',
  overlayClassName,
  title = null,
  children,
  ...rest
}) => {
  const _getClassNames = () => {
    return classNames(
      `modal ${animationClass}`,
      {'is-large': large === true},
      {'is-small': small === true},
      className
    );
  };

  return (
    <ReactModal
      className={_getClassNames()}
      overlayClassName={`modal-overlay animated fadeIn fast ${overlayClassName}`}
      {...rest}>
      {title != null && <div className="title">{title}</div>}
      {children}
    </ReactModal>
  );
};

export const ModalConfirm = ({
  confirmText = 'Yes',
  cancelText = 'No',
  title = 'Are you sure ?',
  onConfirm = () => {},
  onCancel = () => {},
  isConfirming,
  children,
  ...rest
}) => {
  return (
    <Modal small className="modal-confirm" title={title} {...rest}>
      {children}
      <div className="actions">
        <Button
          muted
          disabled={isConfirming}
          text={cancelText}
          onClick={onCancel}
        />
        <Button
          text={confirmText}
          onClick={onConfirm}
          primary
          loading={isConfirming}
        />
      </div>
    </Modal>
  );
};
