import classnames from 'classnames';
import React, {useContext} from 'react';
import {hasFlag} from '../../../../helpers/bitwise';
import {BLOCK_DISMISS_CROSS} from '../../constants/blocks';
import {F_SLOT_HINT, F_SLOT_TOP_BAR} from '../../constants/poke';
import {PokeContext, PokeStateContext} from '../../context';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const BlockDismissCross = () => {
  const {poke} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    onTriggerActions,
  } = useContext(PokeStateContext);

  const block = currentStep.blocks.find((b) => b.type === BLOCK_DISMISS_CROSS);

  if (block == null) {
    return null;
  }

  const {
    fontSize,
    borderRadius,
    fontColor,
    primaryColor: background,
  } = block.style;

  const handleClick = () => {
    onTriggerActions(block.actions || []);
  };

  return (
    <div
      className={classnames('poke-block-dismiss-cross', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_DISMISS_CROSS,
        'is-in-banner': hasFlag(F_SLOT_TOP_BAR, poke.boostFlags),
        'is-in-hint': hasFlag(F_SLOT_HINT, poke.boostFlags),
      })}
      onClick={(e) => {
        e.stopPropagation();
        if (inBuilder === true) {
          onBlockSelected(BLOCK_DISMISS_CROSS);
        } else {
          handleClick();
        }
      }}
      style={{
        background,
        borderRadius,
      }}>
      <i
        style={{borderRadius, fontSize, color: fontColor}}
        className="icon-close"
      />
      <ClickableBlockOverlay />
    </div>
  );
};
