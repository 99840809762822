import {useContext} from 'react';
import {BLOCK_STEPPER, BLOCK_USER} from '../constants/blocks';
import {PokeContext, PokeStateContext} from '../context';

/**
 * Only certain block should have their animation out played every time a step changes.
 * For example :
 *  - title block should always be animated out
 *  - user block should be animated out only when the going step doesn't have a user block
 */
export const useShouldPlayAnimationOut = ({blockType = null}) => {
  const {poke} = useContext(PokeContext);
  const {currentStepIndex, goingToStepIndex} = useContext(PokeStateContext);

  if (poke?.style?.withElementsAnimations === false) {
    return false;
  }
  if (blockType == null || (goingToStepIndex == null) | (poke == null)) {
    return false;
  }
  switch (blockType) {
    // Stepper : if not in going step or position is different or type is different
    case BLOCK_STEPPER:
      const currentStepper = poke.steps[currentStepIndex]?.blocks.find(
        (b) => b.type === BLOCK_STEPPER
      );
      const newStepper = poke.steps[goingToStepIndex]?.blocks.find(
        (b) => b.type === BLOCK_STEPPER
      );
      const currentStepperValue = currentStepper?.style?.type;
      const newStepperValue = newStepper?.style?.type;

      return (
        newStepper == null ||
        currentStepper.style.position !== newStepper?.style?.position ||
        currentStepperValue.type !== newStepperValue?.type
      );
    // User : if not in going step
    case BLOCK_USER:
      return (
        poke.steps[goingToStepIndex]?.blocks.findIndex(
          (b) => b.type === BLOCK_USER
        ) === -1
      );
    default:
      return true;
  }
};
