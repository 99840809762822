import {generalActions} from 'actions';
import classNames from 'classnames';
import {AdvanceIconLike} from 'components/AdvanceIcons/Like';
import Button from 'components/Button';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {voteService} from 'services';
import {Swaler} from 'swaler';
import './_styles.scss';

export const VOTE_CLAP = 100;

const logger = new Swaler('VoteClap');

const VoteClap = ({
  evolution,
  clapped,
  isExtended,
  onVoteStoreUpdate = null,
  onClap = () => {},
}) => {
  const dispatch = useDispatch();

  const jimer = useSelector((state) => generalSelector.getJimer(state));

  const uptEvolutionById = (evolutionId, evolution) =>
    dispatch(generalActions.uptEvolutionById(evolutionId, evolution));

  const [isVoting, setIsVoting] = useState(false);
  const [isClapped, setIsClapped] = useState(clapped);
  const [voteCount, setVoteCount] = useState(evolution.countVotes || 0);

  const handleClap = async (e) => {
    const evolutionId = evolution.uid;
    const myVote = evolution.myVote;

    e.preventDefault();
    e.stopPropagation();
    console.log('TEST VOTE', isVoting);
    if (isVoting === true) {
      return;
    }
    setVoteCount((x) => (isClapped ? x - 1 : x + 1));
    setIsClapped((ic) => !ic);
    setIsVoting(true);
    if (myVote != null) {
      try {
        triggerVoteStoreUpdate(null);
        await voteService.deleteVote(myVote.uid);
      } catch (err) {
        logger.error('Clap vote deleting has failed with error ', err);
      }
    } else {
      try {
        // Let's update directly to prevent weird UX especially if the request is slow
        triggerVoteStoreUpdate({
          uid: 'temp-uid',
          createdAt: new Date().toISOString(),
          evolution,
          jimer,
          value: VOTE_CLAP,
        });
        const myNewVote = await voteService.createEvolutionClapVote({
          evolutionId,
          value: VOTE_CLAP,
          jimer,
        });

        triggerVoteStoreUpdate(myNewVote);
      } catch (err) {
        logger.error('Clap voting has failed with error ', err);
      }
    }
    setIsVoting(false);
  };

  const triggerVoteStoreUpdate = (myVote) => {
    if (onVoteStoreUpdate == null) {
      if (myVote != null) {
        uptEvolutionById(evolution.uid, {
          votes:
            myVote.uid === 'temp-uid'
              ? evolution.votes == null
                ? [myVote]
                : evolution.votes.concat(myVote)
              : evolution.votes == null
              ? [myVote]
              : evolution.votes.map((v) => (v.uid === 'temp-uid' ? myVote : v)),
          myVote,
          ...(myVote.uid === 'temp-uid'
            ? {countVotes: evolution.countVotes + 1}
            : {}),
        });
      } else {
        uptEvolutionById(evolution.uid, {
          votes:
            evolution.votes != null
              ? evolution.votes.filter((v) => v.uid !== evolution.myVote.uid)
              : [],
          myVote,
          countVotes: evolution.countVotes - 1,
        });
      }
    } else {
      onVoteStoreUpdate(myVote);
    }
    onClap(myVote);
  };

  return (
    <Button
      className={classNames('vote-clap')}
      onClick={handleClap}
      rounded={false}>
      <AdvanceIconLike active={isClapped}>
        <span className="like-text">{voteCount}</span>
      </AdvanceIconLike>
    </Button>
  );
};

export default VoteClap;
