import classnames from 'classnames';
import Button from 'components/Button';
import CardSubmitRequest from 'components/CardSubmitRequest';
import {Modal} from 'components/Modal';
import {hasFlag} from 'helpers/bitwise';
import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Link} from 'react-scroll';
import {ROUTE_STANDALONE_FEED} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {F_FEEDBACK_REQUEST_BOX, F_PAGE_FEEDBACK} from 'services/project';
import './_Styles.scss';
import Face1 from './img/face1.svg';
import Face2 from './img/face2.svg';
import Face3 from './img/face3.svg';
import Face4 from './img/face4.svg';
import Face5 from './img/face5.svg';

const StandaloneFeedback = () => {
  const history = useHistory();

  const evolutions =
    useSelector((state) => generalSelector.getEvolutions(state)) || [];
  const project = useSelector((state) => generalSelector.getProject(state));

  const requestActive = hasFlag(
    F_FEEDBACK_REQUEST_BOX,
    project.portalContentFlags
  );

  const [showModalSubmitRequest, setShowModalSubmitRequest] = useState(false);
  const [focused, setFocused] = useState(null);
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    if (animate === true && focused != null) {
      setAnimate(false);
    }
  }, [animate, focused]);

  const classNames = classnames('s-standalone-feedback', {
    'has-no-evolutions': evolutions.length === 0,
    'fade-in-bottom': animate === true,
    'modal-submit-request-open': showModalSubmitRequest,
  });
  const modalClassNames = classnames(
    'modal-submit-request fade-in-bottom centered'
  );

  const filteredEvolutions = evolutions
    .filter((e) => e.section != null)
    .sort(
      (a, b) =>
        new Date(b.lastStepChangeAt).getTime() -
        new Date(a.lastStepChangeAt).getTime()
    );
  const sections = filteredEvolutions.reduce((sections, evolution) => {
    if (evolution.section == null) {
      return sections;
    }
    const indexOfSection = sections.findIndex(
      (s) => s.uid === evolution.section.uid
    );

    if (indexOfSection >= 0) {
      sections[indexOfSection].evolutions.push(evolution);
      return sections;
    }
    return sections.concat({...evolution.section, evolutions: [evolution]});
  }, []);

  if (hasFlag(F_PAGE_FEEDBACK, project.portalContentFlags) === false) {
    history.push(ROUTE_STANDALONE_FEED);
  }

  return (
    <div className={classNames}>
      <div className="feedback-content">
        <div className="feedback-header row">
          <div className="left">
            <div className="title">
              {project.portalWelcomeTitle != null ? (
                project.portalWelcomeTitle
              ) : (
                <Trans
                  i18nKey="others.feedbackHelpUsTitle"
                  values={{projectName: project.name}}></Trans>
              )}
            </div>
            <div className="subtitle">
              {project.portalWelcomeMessage != null ? (
                project.portalWelcomeMessage
              ) : (
                <Trans
                  i18nKey="others.feedbackHelpUsSubtitle"
                  values={{projectName: project.name}}></Trans>
              )}
            </div>
            <div className="actions">
              {requestActive === true && (
                <Button
                  primary
                  className="submit-idea-btn"
                  onClick={() => setShowModalSubmitRequest(true)}>
                  <Trans i18nKey="others.feedbackHelpUsSubmitIdeaBtn" />
                </Button>
              )}
              {filteredEvolutions.length !== 0 && (
                <Link
                  activeClass="active"
                  to="ideations"
                  smooth={true}
                  duration={1000}>
                  <Button className="vote-features-btn">
                    <Trans i18nKey="others.feedbackHelpUsVoteFeaturesBtn" />
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="right">
            <img
              src={Face1}
              alt="cover-give-feedback-face1"
              className="face face1"
            />
            <img
              src={Face2}
              alt="cover-give-feedback-face2"
              className="face face2"
            />
            <img
              src={Face3}
              alt="cover-give-feedback-face3"
              className="face face3"
            />
            <img
              src={Face4}
              alt="cover-give-feedback-face4"
              className="face face4"
            />
            <img
              src={Face5}
              alt="cover-give-feedback-face5"
              className="face face5"
            />
          </div>
        </div>
      </div>
      <Modal isOpen={showModalSubmitRequest} className={modalClassNames}>
        <CardSubmitRequest
          title={
            <Trans
              i18nKey="others.buttonSubmitRequestCardTitle"
              values={{projectName: project.name}}></Trans>
          }
          onClose={() => setShowModalSubmitRequest(false)}
        />
      </Modal>
    </div>
  );
};

export default StandaloneFeedback;
