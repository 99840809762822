import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import Card from 'components/Card';
import Input from 'components/InputGroup';
import {toastSuccess} from 'components/Toaster';
import {hasFlag} from 'helpers/bitwise';
import React from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {ROUTE_STANDALONE_FEED} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {authService, jimerService} from 'services';
import {F_OPTION_PORTAL_DISPLAY_FEED} from 'services/evolution';
import {
  F_EXTRA_PREVENT_PERSONAL_DATA_PROMPT,
  F_FEED_SUBSCRIPTION_BOX,
} from 'services/project';
import './_Styles.scss';

class CardSubscribe extends React.Component {
  constructor() {
    super();
    this.state = {
      inputEmail: '',
      isSubmitting: false,
      submitSuccess: false,
    };
  }

  handleInputChange = ({target}) =>
    this.setState({[target.name]: target.value});
  handleSubmit = async () => {
    const {uptJimer} = this.props;
    const {inputEmail} = this.state;

    this.setState({isSubmitting: true});
    try {
      const jimer = await jimerService.updateJimerPersonalData({
        email: inputEmail,
      });

      uptJimer(jimer);
      this.setState({isSubmitting: false});
      toastSuccess([
        <Trans i18nKey="others.cardSubscribeToastSuccessTitle"></Trans>,
        <Trans i18nKey="others.cardSubscribeToastSuccessBody"></Trans>,
      ]);
    } catch (err) {
      this.logger.error('Updating jimer personal data failed with error', err);
      this.setState({isSubmitting: false});
      throw err;
    }
  };

  render() {
    const {evolutions, match, project} = this.props;
    const {inputEmail, isSubmitting} = this.state;
    const feedEvolutions = evolutions.filter((e) =>
      hasFlag(F_OPTION_PORTAL_DISPLAY_FEED, e.optionsFlags)
    );
    const classNames = classnames('card-subscribe-wrapper fade-in-bottom', {
      'is-low-bottom': project.whiteLabeling === true,
    });

    if (
      hasFlag(F_FEED_SUBSCRIPTION_BOX, project.portalContentFlags) === false
    ) {
      return <></>;
    }
    if (authService.isIdentified() === true) {
      return <></>;
    }
    if (match.path === ROUTE_STANDALONE_FEED && feedEvolutions.length === 0) {
      return <></>;
    }
    if (
      hasFlag(F_EXTRA_PREVENT_PERSONAL_DATA_PROMPT, project.extraFlags) === true
    ) {
      return <></>;
    }
    return (
      <div className={classNames}>
        <Card className="card-subscribe">
          <i className="icon-bell"></i>
          <div className="title">
            <Trans i18nKey="others.cardSubscribeTitle"></Trans>
          </div>
          <p>
            <Trans i18nKey="others.cardSubscribeParagraph"></Trans>
          </p>
          <Input
            placeholder="email@domain.com"
            value={inputEmail}
            name="inputEmail"
            onChange={this.handleInputChange}
            disabled={isSubmitting}></Input>
          <Button primary loading={isSubmitting} onClick={this.handleSubmit}>
            <Trans i18nKey="common.subscribe"></Trans>
          </Button>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  evolutions: generalSelector.getEvolutions(state),
  project: generalSelector.getProject(state),
});
const mapDispatchToProps = (dispatch) => ({
  uptJimer: (jimer) => dispatch(generalActions.uptJimer(jimer)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CardSubscribe));
