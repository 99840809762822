import {generalActions} from 'actions';
import Button from 'components/Button';
import Input from 'components/InputGroup';
import {toastDanger} from 'components/Toaster';
import {hasFlag} from 'helpers/bitwise';
import React, {useState} from 'react';
import {Trans} from 'react-i18next';
import {connect, useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {authService, jimerService} from 'services';
import {F_EXTRA_PREVENT_PERSONAL_DATA_PROMPT} from 'services/project';
import {Swaler} from 'swaler';
import './_Styles.scss';
import image from './img/announcement-empty-state.svg';

const logger = new Swaler('EmptyStateFeed');

const mapDispatchToProps = (dispatch) => ({
  uptJimer: (data) => dispatch(generalActions.uptJimer(data)),
});

export const EmptyStateFeed = connect(
  null,
  mapDispatchToProps
)((props) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [inputEmail, setInputEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const {uptJimer} = props;

    e.preventDefault();
    setSubmitting(true);
    try {
      const jimer = await jimerService.updateJimerPersonalData({
        email: inputEmail,
      });

      uptJimer(jimer);
      setSubmitting(false);
      setInputEmail('');
    } catch (err) {
      logger.error('Updating jimer personal data failed with error', err);
      toastDanger([
        'Ups...',
        'We could not save your information, please try again!',
      ]);
      setSubmitting(false);
      throw err;
    }
  };

  return (
    <div className="empty-state-widget-feed">
      <img src={image} alt="feed empty state" />
      {authService.isIdentified() === true ? (
        <div className="card-identified">
          <div className="title">
            <Trans i18nKey="others.widgetAnnouncementEmptyStateTitle"></Trans>
          </div>
          <p>
            <Trans i18nKey="others.widgetAnnouncementEmptyStateParagraph"></Trans>
          </p>
        </div>
      ) : hasFlag(F_EXTRA_PREVENT_PERSONAL_DATA_PROMPT, project.extraFlags) ===
        false ? (
        <form onSubmit={handleSubmitForm}>
          <Input
            placeholder="email@domain.com"
            value={inputEmail}
            required
            onChange={(e) => setInputEmail(e.target.value)}></Input>
          <Button primary iconRight="icon-send" loading={submitting}>
            <Trans i18nKey="common.subscribe"></Trans>
          </Button>
        </form>
      ) : (
        <div>Our team didn't published content yet, come back later!</div>
      )}
    </div>
  );
});
