import VoteClap from 'components/VoteClap';
import Button from 'components/Button';
import {hasFlag} from 'helpers/bitwise';
import React from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {
  ROUTE_STANDALONE_EVOLUTION_ID,
  ROUTE_WIDGET_EVOLUTION_ID,
} from 'router/routes.const';
import {
  F_OPTION_COMMENTS_ENABLED,
  F_OPTION_LIKE_ENABLED,
} from 'services/evolution';
import './_Styles.scss';
import classNames from 'classnames';

export default function EvolutionStats({
  evolution,
  onClap,
  voteClapProps,
  widgetMode,
  disableCommentLink = false,
  hideComment = false,
  showReadMore = false,
}) {
  const hasLikeEnabled = hasFlag(F_OPTION_LIKE_ENABLED, evolution.optionsFlags);
  const hasCommentEnabled = hasFlag(
    F_OPTION_COMMENTS_ENABLED,
    evolution.optionsFlags
  );

  if (hasLikeEnabled === false && hasCommentEnabled === false) {
    return <></>;
  }
  const isExtended = showReadMore === false;
  const LinkWrapper = ({children}) => (
    <Link
      to={{
        pathname:
          widgetMode === true
            ? ROUTE_WIDGET_EVOLUTION_ID(evolution.uid)
            : ROUTE_STANDALONE_EVOLUTION_ID(evolution.uid),
      }}
    >
      {children}
    </Link>
  );

  return (
    <div
      className={classNames('evolution-stats', {
        'is-extended': isExtended,
      })}
    >
      <div className="actions">
        {hasLikeEnabled === true && (
          <div className="vote-clap-wrapper">
            <VoteClap
              compact
              clapped={evolution.myVote != null}
              evolution={evolution}
              onClap={onClap}
              isExtended={isExtended}
              {...voteClapProps}
            />
          </div>
        )}
        {hideComment !== true &&
          hasFlag(F_OPTION_COMMENTS_ENABLED, evolution.optionsFlags) && (
            <div className="stat-comment">
              {disableCommentLink === false ? (
                <LinkWrapper>
                  <i className="icon-comment-o"></i>
                  <span>
                    {evolution.countComments || 0}
                    <span className="comment-text">
                      <Trans
                        i18nKey="common.comments"
                        count={evolution.countComments || 0}
                      ></Trans>
                    </span>
                  </span>
                </LinkWrapper>
              ) : (
                <div className="stat-comments-wrapper">
                  <i className="icon-comment-o"></i>
                  <span>
                    {evolution.countComments || 0}
                    <span className="comment-text">
                      <Trans
                        i18nKey="common.comments"
                        count={evolution.countComments || 0}
                      ></Trans>
                    </span>
                  </span>
                </div>
              )}
            </div>
          )}
      </div>
      {showReadMore && (
        <div className="stat-read-more">
          <LinkWrapper>
            <Button thin rounded={false} className="body-4">
              <Trans i18nKey="common.readMore" />
              <i className="icon-chevron-right" />
            </Button>
          </LinkWrapper>
        </div>
      )}
    </div>
  );
}
