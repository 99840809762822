import React from 'react';
import {generalSelector} from 'selectors';
import './_Styles.scss';
import JimoLabel from 'scenes/Poke/JimoLabel';

export default () => {
  const project = generalSelector.getProject();

  if (project.whiteLabeling === true) {
    return <></>;
  }
  return (
    <div className="standalone-footer">
      <JimoLabel isFeed />
    </div>
  );
};
