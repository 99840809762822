/**
 * IMPORTANT : All non-default env variables must be prefixed with REACT_APP_
 * See https://create-react-app.dev/docs/adding-custom-environment-variables/
 */

/**
 * IMPORTANT 2 : Use this class instead of process.env
 */

class Env {
  get NODE_ENV() {
    return process.env.NODE_ENV;
  }
  get API_URL() {
    return process.env.REACT_APP_API_URL;
  }
  get SENTRY_DSN() {
    return process.env.REACT_APP_SENTRY_DSN;
  }
  get STORMWIND_DOMAIN() {
    return process.env.REACT_APP_STORMWIND_DOMAIN;
  }
}

export const Environment = new Env();
