import {generalActions} from 'actions';
import classnames from 'classnames';
import {toastDanger} from 'components/Toaster';
import queryString from 'query-string';
import React, {useEffect} from 'react';
import {useQuery} from 'react-query';
import {connect, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {generalSelector} from 'selectors';
import {analyticService, evolutionService} from 'services';
import {EventContext} from 'services/analytic';
import {Swaler} from 'swaler';
import './_styles.scss';
import PostDetails from './components/PostDetails';

const Evolution = ({uptEvolutionById}) => {
  const logger = new Swaler('Evolution');

  const location = useLocation();

  const evolutions = useSelector((state) =>
    generalSelector.getEvolutions(state)
  );
  const project = useSelector((state) => generalSelector.getProject(state));

  const {evolutionId} = useParams();

  useEffect(() => {
    const {from_intercom_messenger} = queryString.parse(location.search);

    if (from_intercom_messenger === 'true') {
      analyticService.createClick({
        evolutionId,
        context: EventContext.PORTAL_WIDGET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEvolution = async () => {
    try {
      const evolution = await evolutionService.getEvolutionById(evolutionId, {
        relations: ['comments', 'comments.likes'],
      });

      uptEvolutionById(evolutionId, evolution);
    } catch (err) {
      logger.error('Fetching evolution failed with err', err);
      return toastDanger([
        'Wups...',
        'We could not fetch this evolution, please try again later!',
      ]);
    }
  };

  // refetch evolution only for comments
  const {isFetching: isLoading} = useQuery({
    queryKey: ['getEvolutionById', evolutionId],
    queryFn: () => fetchEvolution(),
    refetchOnWindowFocus: false,
    onError: (err) => {
      logger.error(`Failed fetching evolution with error ${err}`);
      throw err;
    },
  });

  const getEvolution = () => evolutions.find((e) => e.uid === evolutionId);

  const classNames = classnames('evolution-wrapper', {
    'no-padding-bottom': project.whiteLabeling === true,
  });

  const evolution = getEvolution();

  const isOldVersionPost = !(evolution?.steps?.[0]?.blocks?.length > 0);

  return (
    <div className={classNames}>
      {isOldVersionPost === true ? (
        // @TODO : Remove some time after cleaning is pushed in live (wait to see if some customers are complaining)
        <></>
      ) : (
        <PostDetails evolution={evolution} isCommentsLoading={isLoading} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uptEvolutionById: (evolutionId, evolution) =>
    dispatch(generalActions.uptEvolutionById(evolutionId, evolution)),
});

export default connect(null, mapDispatchToProps)(Evolution);
