import Button from 'components/Button';
import Input from 'components/InputGroup';
import {toastDanger} from 'components/Toaster';
import {func, object} from 'prop-types';
import React, {useState} from 'react';
import {commentService} from 'services';
import {Swaler} from 'swaler';
import './_styles.scss';
import Comment from './comment';
import Avatar from 'shared/front/components/Avatar';

const propTypes = {
  evolution: object.isRequired,
  onReplySubmit: func.isRequired,
  onCommentUpdate: func.isRequired,
  onCommentDelete: func.isRequired,
  onCommentLiked: func.isRequired,
  onCommentUnLiked: func.isRequired,
};

const defaultProps = {};

const logger = new Swaler('EvolutionComments');

const EvolutionComments = ({
  evolution,
  onReplySubmit,
  onCommentLiked,
  onCommentUnLiked,
}) => {
  const [inputEditComment, setInputEditComment] = useState('');
  const [inputReply, setInputReply] = useState('');
  const [commentToEdit, setCommentToEdit] = useState(null);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [commentToReply, setCommentToReply] = useState(null);
  const [isSubmittingReply, setIsSubmittingReply] = useState(false);

  const handleSubmitReply = async (commentToReply) => {
    if (inputReply.length === 0) {
      return;
    }
    setIsSubmittingReply(true);
    try {
      const comment = await commentService.createComment({
        evolutionId: evolution.uid,
        message: inputReply,
        replyToId: commentToReply.uid,
      });

      onReplySubmit(comment, commentToReply);
      setIsSubmittingReply(false);
      setInputReply('');
      setCommentToReply(null);
    } catch (err) {
      logger.error('Creating reply failed with error ', err);
      toastDanger('Creating reply failed');
      setIsSubmittingReply(false);
      setInputReply('');
      setCommentToReply(null);
    }
  };

  const handleCancelReply = () => {
    setInputReply('');
    setCommentToReply(null);
  };

  const showHideModalEditComment = (commentToEdit, commentToReply) => {
    if (commentToEdit == null) {
      setCommentToEdit(null);
      setCommentToReply(null);
      setInputEditComment('');
    } else {
      setCommentToEdit(commentToEdit);
      setCommentToReply(commentToReply);
      setInputEditComment(commentToEdit.message);
    }
  };
  const showHideModalConfirmDelete = (commentToDelete, commentToReply) => {
    setCommentToDelete(commentToDelete);
    setCommentToReply(commentToReply);
  };

  return (
    <div className="comment-list">
      {evolution.comments
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
        .map((c) => (
          <Comment
            key={c.uid}
            comment={c}
            onCommentEdit={() => showHideModalEditComment(c)}
            onCommentLiked={onCommentLiked}
            onCommentUnLiked={onCommentUnLiked}
            onCommentDelete={() => showHideModalConfirmDelete(c)}
            onCommentReply={() => setCommentToReply(c)}
          >
            {c.replies.length > 0 && (
              <div className="replies">
                {c.replies
                  .sort(
                    (a, b) =>
                      new Date(a.createdAt).getTime() -
                      new Date(b.createdAt).getTime()
                  )
                  .map((r) => (
                    <Comment
                      key={r.uid}
                      comment={r}
                      onCommentEdit={() => showHideModalEditComment(r, c)}
                      onCommentLiked={(like) => onCommentLiked(like, c)}
                      onCommentUnLiked={(commentId, likeId) =>
                        onCommentUnLiked(commentId, likeId, c)
                      }
                      onCommentDelete={() => showHideModalConfirmDelete(r, c)}
                      onCommentReply={() => setCommentToReply(c)}
                    />
                  ))}
              </div>
            )}
            {commentToReply != null && commentToReply.uid === c.uid && (
              <div className="reply-wrapper">
                <div className="input-comment-reply-wrapper">
                  <Avatar jimer={c.jimer} hasBackground />
                  <Input
                    className="input-comment-reply body-3"
                    name="inputReply"
                    placeholder="Reply comment"
                    value={inputReply}
                    onChange={({target}) => setInputReply(target.value)}
                    onPressEnter={() => handleSubmitReply(c)}
                    disabled={isSubmittingReply}
                  />
                </div>
                {inputReply !== '' && (
                  <div className="button-group-actions">
                    <Button
                      disabled={isSubmittingReply}
                      onClick={handleCancelReply}
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={isSubmittingReply}
                      primary
                      onClick={() => handleSubmitReply(c)}
                    >
                      {isSubmittingReply ? 'Posting...' : 'Reply'}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Comment>
        ))}
    </div>
  );
};

EvolutionComments.propTypes = propTypes;
EvolutionComments.defaultProps = defaultProps;

export default EvolutionComments;
