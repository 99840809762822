import React from 'react';
import './_Styles.scss';

export default function Loader(props) {
  const {width = '40px'} = props;
  const style = {
    width,
    height: width,
  };

  return <div className="loader" style={style}></div>;
}
