import classnames from 'classnames';
import React from 'react';
import './_Styles.scss';

export default function Divider(props) {
  const {children = null, className, width, dark} = props;
  const classNames = classnames('divider', className, {
    'has-content': children != null,
    'is-dark': dark === true,
  });

  return (
    <div className={classNames} style={{width}}>
      {children != null && [
        <div key={1} className="left-divider"></div>,
        <div key={2} className="content">
          {children}
        </div>,
        <div key={3} className="right-divider"></div>,
      ]}
    </div>
  );
}
