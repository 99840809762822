import classnames from 'classnames';
import Button from 'components/Button';
import {Modal} from 'components/Modal';
import ProjectMembersAvatar from 'components/ProjectMembersAvatar';
import {
  EVOLUTION_STEP_FEATURE_PREVIEW,
  EVOLUTION_STEP_UP_TO_VOTE,
} from 'constants/evolution';
import {hasFlag} from 'helpers/bitwise';
import React from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
  ROUTE_STANDALONE_FEED,
  ROUTE_STANDALONE_FEEDBACK,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {
  F_FEEDBACK_REQUEST_BOX,
  F_FEED_SUBSCRIPTION_BOX,
} from 'services/project';
import CardSubmitRequest from '../../../../components/CardSubmitRequest';
import './_Styles.scss';

class ButtonSubmitRequest extends React.Component {
  constructor() {
    super();
    this.state = {
      showModalSubmitRequest: false,
    };
  }

  showHideModalSubmitRequest = (
    showModalSubmitRequest = !this.state.showModalSubmitRequest
  ) => this.setState({showModalSubmitRequest});

  render() {
    const {
      hideOnMobile,
      project,
      fixed = false,
      match,
      evolutions,
    } = this.props;
    const {showModalSubmitRequest} = this.state;
    const {portalWelcomeTitle, portalWelcomeMessage} = project;
    const classNames = classnames('btn-submit-request fade-in-bottom', {
      'is-hidden-mobile': hideOnMobile === true,
      'is-fixed': fixed === true,
      'is-low-bottom': project.whiteLabeling === true,
    });
    const modalClassNames = classnames('modal-submit-request fade-in-bottom', {
      'is-low-bottom': project.whiteLabeling === true,
    });
    const feedbacks = evolutions.filter(
      (e) =>
        e.step === EVOLUTION_STEP_UP_TO_VOTE ||
        e.step === EVOLUTION_STEP_FEATURE_PREVIEW
    );

    if (
      match.path === ROUTE_STANDALONE_FEED &&
      hasFlag(F_FEED_SUBSCRIPTION_BOX, project.portalContentFlags) === false
    ) {
      return <></>;
    }
    if (match.path === ROUTE_STANDALONE_FEEDBACK && feedbacks.length === 0) {
      return <></>;
    }
    if (hasFlag(F_FEEDBACK_REQUEST_BOX, project.portalContentFlags) === false) {
      return <></>;
    }
    return (
      <>
        {(fixed === false ||
          (fixed === true && showModalSubmitRequest === false)) && (
          <div className={classNames}>
            <div className="title">
              {portalWelcomeTitle != null ? (
                portalWelcomeTitle
              ) : (
                <Trans
                  i18nKey="others.portalWelcomeTitle"
                  values={{projectName: project.name}}></Trans>
              )}
            </div>
            <p>
              {portalWelcomeMessage != null ? (
                portalWelcomeMessage
              ) : (
                <Trans
                  i18nKey="others.portalWelcomeMessage"
                  values={{projectName: project.name}}></Trans>
              )}
            </p>
            <ProjectMembersAvatar></ProjectMembersAvatar>
            <Button
              primary
              iconRight="icon-send"
              onClick={() => this.showHideModalSubmitRequest(true)}>
              <Trans i18nKey="others.buttonSubmitRequest"></Trans>
            </Button>
          </div>
        )}
        <Modal
          isOpen={showModalSubmitRequest}
          className={modalClassNames}
          overlayClassName="overlay-modal-submit-request">
          <CardSubmitRequest
            title={
              <Trans
                i18nKey="others.buttonSubmitRequestCardTitle"
                values={{projectName: project.name}}></Trans>
            }
            onClose={() =>
              this.showHideModalSubmitRequest(false)
            }></CardSubmitRequest>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  project: generalSelector.getProject(state),
  evolutions: generalSelector.getEvolutions(state),
});

export default connect(mapStateToProps)(withRouter(ButtonSubmitRequest));
