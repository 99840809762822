import {generalActions} from 'actions';
import {AppContext} from 'App';
import classnames from 'classnames';
import Button from 'components/Button';
import Card from 'components/Card';
import Input from 'components/InputGroup';
import TextArea from 'components/TextAreaGroup';
import {toastDanger} from 'components/Toaster';
import {hasFlag} from 'helpers/bitwise';
import {bool, func, string} from 'prop-types';
import React, {useContext, useState} from 'react';
import {Trans, withTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import ModalIdentify from 'scenes/Standalone/components/ModalIdentify';
import {generalSelector} from 'selectors';
import {authService, jimerService, requestService} from 'services';
import {
  F_EXTRA_ANONYMOUS_FEEDBACK_DISABLED,
  F_EXTRA_PREVENT_PERSONAL_DATA_PROMPT,
} from 'services/project';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('CardSubmitRequest');

const propTypes = {
  title: string,
  onClose: func,
  flat: bool,
};

const defaultProps = {
  title: null,
  onClose: null,
  flat: false,
};

const CardSubmitRequest = ({t, title, onClose, flat}) => {
  const {theme} = useContext(AppContext);

  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));

  const isAnonymousCommentsDisabled = hasFlag(
    F_EXTRA_ANONYMOUS_FEEDBACK_DISABLED,
    project.extraFlags
  );

  const uptJimer = (data) => dispatch(generalActions.uptJimer(data));

  const [inputRequestTitle, setInputRequestTitle] = useState('');
  const [inputRequestDescription, setInputRequestDescription] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestCreated, setRequestCreated] = useState(null);
  const [skipIdentification, setSkipIdentification] = useState(false);
  const [showModalIdentify, setShowModalIdentify] = useState(
    isAnonymousCommentsDisabled === true && authService.isIdentified() === false
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const requestCreated = await requestService.createRequest({
        title: inputRequestTitle,
        description: inputRequestDescription,
      });

      setRequestCreated(requestCreated);
      setSkipIdentification(authService.isIdentified());
    } catch (err) {
      logger.error(`Creating request failed with error `, err);
      toastDanger(['Wups...', 'An error occurred, please try again!']);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSubmitIdentification = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const jimer = await jimerService.updateJimerPersonalData({
        email: inputEmail,
      });

      uptJimer(jimer);
      setSkipIdentification(true);
    } catch (err) {
      logger.error('Updating jimer personal data failed with error', err);
      throw err;
    } finally {
      setIsSubmitting(false);
    }
  };

  const classNames = classnames('card-submit-request', {
    'has-close-button': onClose != null,
    'is-flat': flat === true,
  });

  if (
    isAnonymousCommentsDisabled === true &&
    authService.isIdentified() === false
  ) {
    return (
      <ModalIdentify
        isOpen={showModalIdentify}
        type="beforeComment"
        onRequestClose={
          onClose != null ? onClose : () => setShowModalIdentify(false)
        }
      />
    );
  }

  const {callToAction} = theme ?? {};
  const {backgroundColor, borderColor, fontColor} = callToAction ?? {};

  return (
    <>
      <div className="card-submit-request-wrapper">
        {onClose != null && (
          <Button className="btn-back" muted onClick={onClose}>
            <i className="icon-chevron-left"></i>
          </Button>
        )}
        <Card className={classNames}>
          {onClose != null && (
            <Button className="btn-close" muted iconOnly onClick={onClose}>
              <i className="icon-close"></i>
            </Button>
          )}
          {requestCreated == null ? (
            <form onSubmit={handleSubmit}>
              {title != null && <div className="title">{title}</div>}
              <Input
                placeholder={t('others.cardSubmitRequestTitlePlaceholder')}
                name="inputRequestTitle"
                onChange={(e) => setInputRequestTitle(e.target.value)}
                value={inputRequestTitle}
                required
              />
              <TextArea
                placeholder={t(
                  'others.cardSubmitRequestDescriptionPlaceholder'
                )}
                name="inputRequestDescription"
                onChange={(e) => setInputRequestDescription(e.target.value)}
                value={inputRequestDescription}
              />
              <Button
                style={{
                  ...(backgroundColor != null
                    ? {
                        backgroundColor,
                      }
                    : {}),
                  ...(borderColor != null
                    ? {
                        border: `1px solid ${borderColor}`,
                      }
                    : {}),

                  ...(fontColor != null
                    ? {
                        color: fontColor,
                      }
                    : {}),
                }}
                iconLeft="icon-send-o"
                primary
                loading={isSubmitting}>
                <Trans i18nKey="others.cardSubmitRequestBtnSubmit"></Trans>
              </Button>
            </form>
          ) : skipIdentification === false &&
            hasFlag(
              F_EXTRA_PREVENT_PERSONAL_DATA_PROMPT,
              project.extraFlags
            ) === false ? (
            <form
              className="request-created-ask-identification fade-in-top"
              onSubmit={handleSubmitIdentification}>
              <p className="title">
                <Trans i18nKey="others.cardSubmitRequestThank"></Trans>
              </p>
              <p>
                <Trans i18nKey="others.cardSubmitRequestFollowUp"></Trans>
              </p>
              <Input
                type="email"
                placeholder="email@domain.com"
                name="inputEmail"
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
                required></Input>
              <div className="actions">
                <Button
                  light
                  onClick={() => setSkipIdentification(true)}
                  disabled={isSubmitting}
                  type="button">
                  <Trans i18nKey="common.skip"></Trans>
                </Button>
                <Button iconLeft="icon-send" primary loading={isSubmitting}>
                  <Trans i18nKey="common.send"></Trans>
                </Button>
              </div>
            </form>
          ) : (
            <div className="request-created fade-in-top">
              <p>
                <Trans i18nKey="others.cardSubmitRequestThank"></Trans>
              </p>
              <Button
                iconLeft="icon-send"
                primary
                onClick={() => {
                  setRequestCreated(null);
                  setInputRequestTitle('');
                  setInputRequestDescription('');
                }}>
                <Trans i18nKey="others.cardSubmitRequestBtnSendAnother"></Trans>
              </Button>
            </div>
          )}
        </Card>
      </div>
      <ModalIdentify
        isOpen={showModalIdentify}
        type="beforeComment"
        onRequestClose={() => setShowModalIdentify(false)}
      />
    </>
  );
};

CardSubmitRequest.propTypes = propTypes;
CardSubmitRequest.defaultProps = defaultProps;

export default withTranslation()(CardSubmitRequest);
