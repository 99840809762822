import Store from 'store/configure';

export const getProject = (store = Store().get().store.getState()) => {
  return store.general.project;
};
export const getProjectId = (store = Store().get().store.getState()) => {
  const project = store.general.project;

  return project != null ? project.uid : undefined;
};
export const getProjectJimer = (
  projectId,
  store = Store().get().store.getState()
) => {
  return store.general.projectsJimers[projectId];
};
export const getProjectJimerToken = (
  projectId,
  store = Store().get().store.getState()
) => {
  if (store.general.projectsJimers[projectId] == null) {
    return null;
  }
  return store.general.projectsJimers[projectId].token;
};
export const getJimer = (store = Store().get().store.getState()) => {
  return store.general.jimer;
};
export const getEvolutions = (store = Store().get().store.getState()) => {
  return store.general.evolutions;
};
export const getEvolutionById = (
  store = Store().get().store.getState(),
  evolutionId
) => {
  return store.general.evolutions.find((e) => e.uid === evolutionId);
};
export const getWidgetMode = (store = Store().get().store.getState()) => {
  return store.general.widgetMode;
};
export const getAnalyticViewHistory = (
  store = Store().get.store.getState()
) => {
  return store.general.analyticViewHistory;
};
export const getCurrentUrl = (store = Store().get().store.getState()) => {
  return store.general.currentUrl;
};
export const getHideTopNav = (store = Store().get().Store.getState()) => {
  return store.general.hideTopNav;
};
