import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React, {useContext, useEffect} from 'react';
import sanitizeHtml from 'sanitize-html';
import {hasFlag} from '../../../../helpers/bitwise';
import {
  BLOCK_BODY,
  BLOCK_CHOICE,
  BLOCK_CONCEPT,
  BLOCK_INTERVIEW,
  BLOCK_LABEL,
  BLOCK_MEDIA,
  BLOCK_NPS,
  BLOCK_OPEN_QUESTION,
  BLOCK_OPINION,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_SLIDER,
  BLOCK_STEPPER,
  BLOCK_TITLE,
  BLOCK_USER,
} from '../../constants/blocks';
import {F_SLOT_HINT, F_SLOT_TOP_BAR} from '../../constants/poke';
import {PokeContext, PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {MEDIA_POSITION_BOTTOM, MEDIA_POSITION_TOP} from '../BlockMedia';
import {VariableToSpan} from '../BlockTitle';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './styles.scss';

export const decodeHtml = (html) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const BlockBody = () => {
  const {poke, user} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    language,
    onUrlClick,
    addFontFamily,
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_BODY,
  });

  const block = currentStep.blocks.find((b) => b.type === 'BODY');
  const {fontColor, fontFamily, align, ...restStyle} = block.style;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const translation = block.translations?.find((t) => t.language === language);

  const sanitizedContent = sanitizeHtml(
    translation != null
      ? decodeHtml(translation.value)
      : decodeHtml(block.value),
    {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'img',
        'iframe',
        'ins',
        'del',
        'variable',
      ]),
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        a: ['href', 'target'],
        variable: ['class', 'data-attribute', 'data-fallback', 'data-source'],
      },
    }
  );

  const isInPokeBanner = hasFlag(F_SLOT_TOP_BAR, poke.boostFlags);

  const isTopBlock =
    isInPokeBanner !== true &&
    currentStep.blocks.some((b) => {
      return (
        b.type === BLOCK_TITLE ||
        b.type === BLOCK_LABEL ||
        b.type === BLOCK_USER ||
        (b.type === BLOCK_STEPPER && b.style.position === 'top') ||
        (b.type === BLOCK_MEDIA && b.style.position === MEDIA_POSITION_TOP)
      );
    }) !== true;

  const isBottomBlock =
    isInPokeBanner !== true &&
    currentStep.blocks.some((b) => {
      return (
        (b.type === BLOCK_MEDIA &&
          b.style.position === MEDIA_POSITION_BOTTOM) ||
        (b.type === BLOCK_STEPPER && b.style.position === 'bottom') ||
        b.type === BLOCK_CHOICE ||
        b.type === BLOCK_INTERVIEW ||
        b.type === BLOCK_NPS ||
        b.type === BLOCK_OPINION ||
        b.type === BLOCK_OPEN_QUESTION ||
        b.type === BLOCK_SLIDER ||
        b.type === BLOCK_CONCEPT ||
        (b.type === BLOCK_PRIMARY_CTA && b.removed !== true) ||
        (b.type === BLOCK_SECONDARY_CTA && b.removed !== true)
      );
    }) !== true;

  const isHint = hasFlag(F_SLOT_HINT, poke.boostFlags);

  document.documentElement.style.setProperty(
    '--poke-block-body-font-size',
    `${restStyle.fontSize}px`
  );

  const ATagtoDiv = ({children, ...props}) => {
    const {href} = props;
    return (
      <span className="a-div" onClick={() => onUrlClick(href)}>
        {children}
      </span>
    );
  };

  return (
    <div
      className={classnames('poke-block-body', `align-${align}`, {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        'is-in-poke-banner': isInPokeBanner === true,
        selected: selectedBlock === BLOCK_BODY,
        'is-top-block': isTopBlock,
        'is-bottom-block': isBottomBlock,
      })}
      style={{
        ...restStyle,
        fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
        color: fontColor,
      }}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_BODY);
      }}>
      <Markdown
        className="body-markdown"
        options={{
          overrides: {
            a: {
              component: ATagtoDiv,
            },
            variable: {
              component: (props) => (
                <VariableToSpan {...props} user={user} inBuilder={inBuilder} />
              ),
            },
          },
        }}>
        {sanitizedContent}
      </Markdown>
      <ClickableBlockOverlay withoutOverflow={isHint} />
    </div>
  );
};
