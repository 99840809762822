import {generalActions} from 'actions';
import Button from 'components/Button';
import Input from 'components/InputGroup';
import {Modal} from 'components/Modal';
import {toastDanger} from 'components/Toaster';
import {bool, func, string} from 'prop-types';
import React, {useState} from 'react';
import {Trans} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {jimerService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

export const TYPE_WHO_ARE_YOU = 'MODAL_IDENTIFY_TYPE_WHO_ARE_YOU';
export const TYPE_ACTIVE_MEMBER = 'MODAL_IDENTIFY_TYPE_ACTIVE_MEMBER';

const logger = new Swaler('ModalIdentify');

const propTypes = {
  isOpen: bool,
  onRequestClose: func,
  type: string,
};

const defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
  type: 'manual',
};

const ModalIdentify = ({onRequestClose, type, isOpen, ...rest}) => {
  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));
  const updateJimer = (data) => dispatch(generalActions.uptJimer(data));

  const [inputEmail, setInputEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const jimer = await jimerService.updateJimerPersonalData({
        email: inputEmail,
      });

      updateJimer(jimer);
      setIsSubmitting(false);
      setInputEmail('');
      onRequestClose();
    } catch (err) {
      logger.error('Updating jimer personal data failed with error', err);
      toastDanger([
        'Ups...',
        'We could not save your information, please try again!',
      ]);
      setIsSubmitting(false);
      throw err;
    }
  };

  let textKey = 'others.modalIdentifyParagraph';

  if (type === 'afterComment') {
    textKey = 'others.modalIdentifyAfterCommentParagraph';
  }
  if (type === 'beforeComment') {
    textKey = 'others.modalIdentifyBeforeCommentParagraph';
  }

  return (
    <Modal className="modal-identify" isOpen={isOpen} {...rest}>
      <div className="close-wrapper">
        <Button muted iconOnly onClick={onRequestClose}>
          <i className="icon-close" />
        </Button>
      </div>
      <p className="title">
        <Trans i18nKey="others.modalIdentifyTitle" />
      </p>
      <p>
        <Trans i18nKey={textKey} values={{projectName: project.name}} />
      </p>
      <form onSubmit={handleSubmit}>
        <Input
          type="email"
          value={inputEmail}
          onChange={(e) => setInputEmail(e.target.value)}
          name="inputEmail"
          placeholder="email@domain.com"
          disabled={isSubmitting}></Input>
        <Button primary loading={isSubmitting}>
          <Trans i18nKey="common.identify" />
        </Button>
      </form>
    </Modal>
  );
};

ModalIdentify.propTypes = propTypes;
ModalIdentify.defaultProps = defaultProps;

export default ModalIdentify;
