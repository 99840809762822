import classnames from 'classnames';
import Lottie from 'lottie-react';
import React, {useRef} from 'react';
import skeleton from './skeleton.json';

export const StormwindPortalLoader = () => {
  const refLottie = useRef(null);

  return (
    <div className={classnames('stormwind-portal-loader', {})}>
      <Lottie
        lottieRef={refLottie}
        animationData={skeleton}
        autoPlay={true}
        loop
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </div>
  );
};
