import classnames from 'classnames';
import React, {useContext, useEffect} from 'react';
import {hasFlag} from '../../../../helpers/bitwise';
import {
  BLOCK_LABEL,
  BLOCK_MEDIA,
  BLOCK_STEPPER,
  BLOCK_USER,
} from '../../constants/blocks';
import {F_SLOT_HINT, F_SLOT_TOP_BAR} from '../../constants/poke';
import {PokeContext, PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {MEDIA_POSITION_TOP} from '../BlockMedia';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const BlockLabel = () => {
  const {poke} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    language,
    addFontFamily,
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_LABEL,
  });

  const block = currentStep.blocks.find((b) => b.type === BLOCK_LABEL) ?? {};
  const {value, style} = block;
  const {fontColor, fontFamily, primaryColor, align, ...restStyle} =
    style ?? {};

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const isInPokeBanner = hasFlag(F_SLOT_TOP_BAR, poke.boostFlags);

  const isTopBlock =
    isInPokeBanner !== true &&
    currentStep.blocks.some((b) => {
      return (
        b.type === BLOCK_USER ||
        (b.type === BLOCK_STEPPER && b.style.position === 'top') ||
        (b.type === BLOCK_MEDIA && b.style.position === MEDIA_POSITION_TOP)
      );
    }) !== true;

  const translation = block.translations?.find((t) => t.language === language);

  const isHint = hasFlag(F_SLOT_HINT, poke.boostFlags);

  return (
    <div
      className={classnames('poke-block-label', {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        'is-in-poke-banner': isInPokeBanner === true,
        selected: selectedBlock === BLOCK_LABEL,
        'is-top-block': isTopBlock,
        'align-center': align === 'center',
        'align-right': align === 'right',
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_LABEL);
      }}>
      <div
        className="label-wrapper"
        style={{
          ...restStyle,
          color: fontColor,
          fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
          backgroundColor: primaryColor,
          ...(align === 'right'
            ? {marginLeft: 'auto'}
            : align === 'center'
            ? {marginLeft: 'auto', marginRight: 'auto'}
            : {marginRight: 'auto'}),
        }}>
        {translation != null ? translation.value : value}
      </div>
      <ClickableBlockOverlay withoutOverflow={isHint} />
    </div>
  );
};
