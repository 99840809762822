import * as analytic from './analytic';
import * as auth from './auth';
import * as comment from './comment';
import * as evolution from './evolution';
import * as fileViewer from './file-viewer';
import * as jimer from './jimer';
import * as project from './project';
import * as prototype from './prototype';
import * as request from './request';
import * as tags from './tags';
import * as vote from './vote';
import * as steps from './steps';
import * as survey from './survey';

export const authService = auth;
export const evolutionService = evolution;
export const requestService = request;
export const voteService = vote;
export const commentService = comment;
export const fileViewerService = fileViewer;
export const prototypeService = prototype;
export const projectService = project;
export const jimerService = jimer;
export const tagsService = tags;
export const analyticService = analytic;
export const stepsService = steps;
export const surveyService = survey;
