import fontColorContrast from 'font-color-contrast';
import {useContext} from 'react';
import {
  BACKGROUND_TYPE_COLOR,
  BACKGROUND_TYPE_GRADIENT,
} from '../components/BlockGradient';
import {PokeContext} from '../context';

// Position
// In a case of gradient, we need to know the position of the element
// to either use the primary of the secondary color
export const useBackgroundContrastedColor = (position) => {
  const {poke} = useContext(PokeContext);

  if (poke.style == null) {
    return fontColorContrast('#ffffff');
  }
  if (poke?.style?.background?.type === BACKGROUND_TYPE_COLOR) {
    return poke?.style?.background?.primaryColor?.length > 7
      ? fontColorContrast(poke?.style?.background?.primaryColor.slice(0, 7))
      : fontColorContrast(poke?.style?.background?.primaryColor);
  }
  if (poke?.style?.background?.type === BACKGROUND_TYPE_GRADIENT) {
    if (poke?.style?.background?.animated === true) {
      return fontColorContrast('#ffffff');
    }
    if (position === 'top') {
      return poke?.style?.background?.primaryColor?.length > 7
        ? fontColorContrast(poke?.style?.background?.primaryColor.slice(0, 7))
        : fontColorContrast(poke?.style?.background?.primaryColor);
    } else {
      return poke?.style?.background?.secondaryColor?.length > 7
        ? fontColorContrast(poke?.style?.background?.secondaryColor.slice(0, 7))
        : fontColorContrast(poke?.style?.background?.secondaryColor);
    }
  }
};
