import React, {useEffect} from 'react';
import StandaloneButtonSubmitRequest from 'scenes/Standalone/components/ButtonSubmitRequest';
import StandaloneCardSubscribe from 'scenes/Standalone/components/CardSubscribe';
import StandaloneFooter from 'scenes/Standalone/components/Footer';
import StandaloneTopNav from 'scenes/Standalone/components/TopNav';
import WidgetFooter from 'scenes/Widget/components/Footer';
import WidgetHeader from 'scenes/Widget/components/Header';
import {Messenger} from 'managers/messenger';
import {VIEW_DEFAULT_WIDTH, VIEW_POST_DETAIL_WIDTH} from 'constants/ui';

const Layout = ({component: Component, isIntercom = false, ...props}) => {
  useEffect(() => {
    Messenger.sendChangeWidth({
      width: VIEW_DEFAULT_WIDTH,
    });
  }, []);

  return (
    <div className="layout layout-widget-basic">
      <WidgetHeader isIntercom={isIntercom} />
      <Component {...props} isIntercom={isIntercom} />
      <WidgetFooter />
    </div>
  );
};
export const IntercomBasicLayout = ({component: Component, ...props}) => (
  <Layout isIntercom component={Component} props={props} />
);

export const WidgetBasicLayout = ({component: Component, ...props}) => (
  <Layout component={Component} props={props} />
);

export const WidgetDetailLayout = ({component: Component, ...props}) => {
  useEffect(() => {
    Messenger.sendChangeWidth({
      width: VIEW_POST_DETAIL_WIDTH,
    });
  }, []);
  return (
    <div className="layout layout-widget-detail">
      <WidgetHeader />
      <Component {...props} />
      <WidgetFooter />
    </div>
  );
};

export const StandaloneBasicLayout = ({
  component: Component,
  layoutProps = {},
  ...props
}) => {
  const {
    hideBtnSubmitRequest = false,
    hideTopNavOnMobile = false,
    hideBtnSubmitRequestOnMobile = false,
    showCardSubscribe = false,
  } = layoutProps;
  useEffect(() => {
    Messenger.sendChangeWidth({
      width: VIEW_DEFAULT_WIDTH,
    });
  }, []);

  return (
    <div className="layout layout-standalone-basic">
      <StandaloneTopNav hideOnMobile={hideTopNavOnMobile} />
      {hideBtnSubmitRequest === false && (
        <StandaloneButtonSubmitRequest
          hideOnMobile={hideBtnSubmitRequestOnMobile}
          fixed
        />
      )}
      {showCardSubscribe === true && <StandaloneCardSubscribe />}
      <Component {...props} />
      <StandaloneFooter />
    </div>
  );
};

export const EmptyLayout = ({component: Component, ...props}) => (
  <div className="layout layout-empty">
    <Component {...props} />
  </div>
);

export const EmptyIntercomLayout = ({component: Component, ...props}) => (
  <div className="layout layout-empty">
    <Component {...props} isIntercom />
  </div>
);
