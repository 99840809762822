import Axios from 'axios';
import {Environment} from './env';

// const logger = new Swaler('Axios');

Axios.defaults.baseURL = Environment.API_URL;

// Axios.interceptors.response.use(
//   (response) => {
//     // 2xx responses
//     return response;
//   },
//   (error) => {
//     if (error.response) {
//       // If 401, check if jwt token exists and expired
//       if (error.response.status === 401) {
//         if (authService.isAuthenticated() === false) {
//           logger.warn(`A request ended with 401 because session expired, redirecting user to enter to renew the session!`)
//           // TODO Dispatch a store update
//         }
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export const setBearerToken = (token) => {
  Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};
export const unsetBearerToken = () => {
  delete Axios.defaults.headers.common['Authorization'];
};
