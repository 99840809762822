import {
  Message,
  sendDalaranMessage,
  sendUndercityMessage,
} from 'managers/messenger/helper';
import {useEffect} from 'react';
import {Swaler} from 'swaler';
import {func} from 'prop-types';

/**
 * List of actions
 */
const BLOCK_SELECTED = 'BLOCK_SELECTED';
const POKE_HEIGHT_CHANGE = 'POKE_HEIGHT_CHANGE';
const CONTAINER_STYLE_CHANGE = 'CONTAINER_STYLE_CHANGE';
const BUILDER_STATE_UPDATE = 'BUILDER_STATE_UPDATE';
const BLOCK_HOVERED = 'BLOCK_HOVERED';
const BUILDER_STATE_REQUEST = 'BUILDER_STATE_REQUEST';
const POST_PREVIEW_UPDATE = 'POST_PREVIEW_UPDATE';
const CHANGELOG_OVERRIDE_THEME = 'CHANGELOG_OVERRIDE_THEME';
const CHANGELOG_OVERRIDE_LANGUAGE = 'CHANGELOG_OVERRIDE_LANGUAGE';
const PROJECT_UPDATE = 'PROJECT_UPDATE';
const CHANGELOG_OVERRIDE_VIEW = 'CHANGELOG_OVERRIDE_VIEW';

const logger = new Swaler('useStormwindMessenger');

const propTypes = {
  onBuilderStateChange: func,
  onPostPreviewUpdate: func,
  onChangelogOverrideTheme: func,
  onChangelogOverrideLanguage: func,
  onProjectUpdate: func,
  onChangelogOverrideView: func,
};

const defaultProps = {
  onBuilderStateChange: () => {},
  onPostPreviewUpdate: () => {},
  onChangelogOverrideTheme: () => {},
  onChangelogOverrideLanguage: () => {},
  onProjectUpdate: () => {},
  onChangelogOverrideView: () => {},
};

const useStormwindMessenger = ({
  onBuilderStateChange = () => {},
  onPostPreviewUpdate,
  onChangelogOverrideTheme,
  onChangelogOverrideLanguage,
  onProjectUpdate,
  onChangelogOverrideView,
}) => {
  const sendBlockSelected = (type) => {
    const message = new Message(BLOCK_SELECTED, {type});

    sendDalaranMessage(message);
  };

  const sendPokeHeightChange = (height, selectedStepId) => {
    const message = new Message(POKE_HEIGHT_CHANGE, {height, selectedStepId});

    sendDalaranMessage(message);
  };

  const sendContainerStyleChange = (style) => {
    const message = new Message(CONTAINER_STYLE_CHANGE, {style});

    sendUndercityMessage(message);
  };

  const sendBlockHovered = (hovered) => {
    const message = new Message(BLOCK_HOVERED, {hovered});

    sendUndercityMessage(message);
  };

  const sendRequestBuilderState = () => {
    const message = new Message(BUILDER_STATE_REQUEST);

    sendDalaranMessage(message);
  };

  const recvHandler = (rawMessage) => {
    try {
      const iframeResizerIdentifier = `[iFrameSizer]message:`;
      if (
        rawMessage.data.slice(0, iframeResizerIdentifier.length) ===
        iframeResizerIdentifier
      ) {
        const message = JSON.parse(
          rawMessage.data.substring(iframeResizerIdentifier.length)
        );

        if (!message.action) {
          return logger.debug(`Received miss-formatted message`);
        }
        const {data, action} = message;

        switch (action) {
          case BUILDER_STATE_UPDATE: {
            onBuilderStateChange(data);
            break;
          }

          case POST_PREVIEW_UPDATE: {
            onPostPreviewUpdate(data);
            break;
          }

          case CHANGELOG_OVERRIDE_THEME: {
            onChangelogOverrideTheme(data);
            break;
          }

          case CHANGELOG_OVERRIDE_LANGUAGE: {
            onChangelogOverrideLanguage(data);
            break;
          }

          case PROJECT_UPDATE: {
            onProjectUpdate(data);
            break;
          }

          case CHANGELOG_OVERRIDE_VIEW: {
            onChangelogOverrideView(data);
            break;
          }

          default: {
            return logger.debug(`Received unknown action ${action}`);
          }
        }
        logger.debug(
          `Handled action ${action} with data ${JSON.stringify(data)}`
        );
      }
    } catch (err) {}
  };

  useEffect(() => {
    window.addEventListener('message', recvHandler);

    return () => window.removeEventListener('message', recvHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    sendBlockSelected,
    sendContainerStyleChange,
    sendPokeHeightChange,
    sendBlockHovered,
    sendRequestBuilderState,
  };
};

useStormwindMessenger.propTypes = propTypes;
useStormwindMessenger.defaultProps = defaultProps;

export default useStormwindMessenger;
