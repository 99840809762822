import classnames from 'classnames';
import React from 'react';
import './_styles.scss';

const Card = (props) => {
  const {widgetMode, ...rest} = props;
  const _getClassName = () => {
    const {muted, widgetMode} = props;

    return classnames('card', props.className, {
      muted: muted === true,
      'widget-mode': widgetMode === true,
    });
  };

  return (
    <div {...rest} className={_getClassName()}>
      {props.children}
    </div>
  );
};

export default Card;
