import classnames from 'classnames';
import React, {useContext} from 'react';
import {BLOCK_CONCEPT} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const parseConceptValue = (value) => {
  const [type, conceptUrl, thumbnailUrl, altText] = value.split(';');

  return {
    type,
    conceptUrl,
    thumbnailUrl,
    altText,
  };
};

export const BlockConcept = () => {
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    onConceptOpen,
    inBuilder,
  } = useContext(PokeStateContext);

  const block = currentStep.blocks.find((b) => b.type === BLOCK_CONCEPT);
  const {conceptUrl, thumbnailUrl} = parseConceptValue(block.value);
  const {borderRadius, ...restStyle} = block.style;

  return (
    <div
      className={classnames('poke-block-concept', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_CONCEPT,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_CONCEPT);
      }}>
      <div
        className="concept-thumbnail"
        style={{
          backgroundImage: `url(${thumbnailUrl})`,
          borderRadius,
          ...restStyle,
        }}
        onClick={() => {
          if (currentStep?.prototypes[0]?.steps?.length > 0) {
            onConceptOpen(block);
          }
        }}>
        <div className="concept-btn-play">
          <i className="icon-play"></i>
        </div>
        <div className="concept-thumbnail-overlay" style={{borderRadius}}></div>
      </div>
      <ClickableBlockOverlay />
    </div>
  );
};
