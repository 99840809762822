import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './_styles.scss';

export default class Button extends React.Component {
  _getClassName = () => {
    const {
      className,
      muted,
      primary,
      secondary,
      inverted,
      loading,
      rounded,
      fullwidth,
      iconLeft,
      iconRight,
      thin,
      large,
      light,
      active,
      iconPadding,
      iconSize,
      iconOnly,
    } = this.props;

    return classNames('btn', className, {
      'is-default': !muted && !primary && !secondary,
      'is-muted': muted,
      'is-secondary': secondary,
      'is-primary': primary,
      'is-inverted': inverted,
      'is-disabled': this.isDisabled(),
      'is-loading': loading,
      'is-not-rounded': rounded === false,
      'is-fullwidth': fullwidth,
      'is-thin': thin,
      'is-large': large,
      icon: iconLeft != null || iconRight != null,
      'left-icon': iconLeft != null,
      'right-icon': iconRight != null,
      'is-light': light,
      'is-active': active,
      'is-icon-padding-large': iconPadding === 'large',
      'is-icon-padding-medium': iconPadding === 'medium',
      'is-icon-small': iconSize === 'small',
      'is-icon-tiny': iconSize === 'tiny',
      'is-icon-only': iconOnly,
    });
  };

  isDisabled = () => this.props.loading || this.props.disabled;

  handleClick = (e) => {
    if (this.isDisabled() === false) {
      this.props.onClick(e);
    } else {
      e.preventDefault();
    }
  };
  render() {
    const {text, iconLeft, iconRight, type = 'submit', style} = this.props;
    const content = this.props.children != null ? this.props.children : text;

    return (
      <button
        className={this._getClassName()}
        onClick={this.handleClick}
        type={type}
        style={style}>
        {iconLeft != null && <i className={`icon-left ${iconLeft}`} />}
        {content}
        {iconRight != null && <i className={`icon-right ${iconRight}`} />}
      </button>
    );
  }
}

Button.propTypes = {
  text: PropTypes.any,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  fullwidth: PropTypes.bool,
  thin: PropTypes.bool,
  large: PropTypes.bool,
  light: PropTypes.bool,
  active: PropTypes.bool,
  iconPadding: PropTypes.string,
  iconSize: PropTypes.string,
  iconOnly: PropTypes.bool,
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  thin: false,
  large: false,
  light: false,
  active: false,
  iconOnly: false,
  inverted: false,
  onClick: () => {},
};
