import {defaultCountEvolutionState} from 'reducers/general';

export const GENERAL_SET_PROJECT = 'GENERAL_SET_PROJECT';
export const GENERAL_SET_PROJECT_JIMER = 'GENERAL_SET_PROJECT_JIMER';
export const GENERAL_SET_JIMER = 'GENERAL_SET_JIMER';
export const GENERAL_SET_EVOLUTIONS = 'GENERAL_SET_EVOLUTIONS';
export const GENERAL_SET_COUNT_EVOLUTIONS = 'GENERAL_SET_COUNT_EVOLUTIONS';
export const GENERAL_UPT_EVOLUTION_BY_ID = 'GENERAL_UPT_EVOLUTION_BY_ID';
export const GENERAL_UPT_JIMER = 'GENERAL_UPT_JIMER';
export const GENERAL_SET_TIP_WELCOME_HIDDEN = 'GENERAL_SET_HIDE_TIP_WELCOME';
export const GENERAL_SET_WIDGET_MODE = 'GENERAL_SET_WIDGET_MODE';
export const GENERAL_SET_DALARAN_PREVIEW_MODE =
  'GENERAL_SET_DALARAN_PREVIEW_MODE';
export const GENERAL_UPT_PROJECT = 'GENERAL_UPT_PROJECT';
export const GENERAL_UPT_ANALYTIC_VIEW_HISTORY =
  'GENERAL_UPT_ANALYTIC_VIEW_HISTORY';
export const GENERAL_SET_CURRENT_URL = 'GENERAL_SET_CURRENT_URL';
export const GENERAL_SET_HIDE_TOP_NAV = 'GENERAL_SET_HIDE_TOP_NAV';
export const GENERAL_SET_IS_PREVIEWING = 'GENERAL_SET_IS_PREVIEWING';
export const GENERAL_SET_PREVIEWED_POST = 'GENERAL_SET_PREVIEWED_POST';

export const setProject = (project) => ({
  type: GENERAL_SET_PROJECT,
  project,
});
export const setProjectJimer = (project, jimer) => ({
  type: GENERAL_SET_PROJECT_JIMER,
  project,
  jimer,
});
export const setJimer = (jimer) => ({
  type: GENERAL_SET_JIMER,
  jimer,
});
export const setEvolutions = (evolutions = []) => ({
  type: GENERAL_SET_EVOLUTIONS,
  evolutions,
});
export const setCountEvolutions = (count = defaultCountEvolutionState) => ({
  type: GENERAL_SET_COUNT_EVOLUTIONS,
  count,
});
export const setWidgetMode = (widgetMode = false) => ({
  type: GENERAL_SET_WIDGET_MODE,
  widgetMode,
});

export const uptEvolutionById = (evolutionId, evolution) => ({
  type: GENERAL_UPT_EVOLUTION_BY_ID,
  evolutionId,
  evolution,
});
export const uptJimer = (data) => ({
  type: GENERAL_UPT_JIMER,
  data,
});
export const uptProject = (data) => ({
  type: GENERAL_UPT_PROJECT,
  data,
});
export const uptAnalyticViewHistory = (data) => ({
  type: GENERAL_UPT_ANALYTIC_VIEW_HISTORY,
  data,
});

export const setTipWelcomeHidden = (isHidden = false) => ({
  type: GENERAL_SET_TIP_WELCOME_HIDDEN,
  isHidden,
});

export const setCurrentUrl = (url) => ({
  type: GENERAL_SET_CURRENT_URL,
  url,
});

export const setDalaranPreviewMode = (value = false) => ({
  type: GENERAL_SET_DALARAN_PREVIEW_MODE,
  value,
});
export const setHideTopNav = (value = false) => ({
  type: GENERAL_SET_HIDE_TOP_NAV,
  value,
});
export const setIsPreviewing = (value = false) => ({
  type: GENERAL_SET_IS_PREVIEWING,
  value,
});

export const setPreviewedPost = (post) => ({
  type: GENERAL_SET_PREVIEWED_POST,
  post,
});
