import React from 'react';
import {useContext} from 'react';
import {PostContext} from '../../context';

const PostBlockSocials = ({showReadMore}) => {
  const {PostBlockSocialsContent, post} = useContext(PostContext);
  return (
    <div className="post-block-socials">
      {PostBlockSocialsContent != null && (
        <PostBlockSocialsContent
          evolution={post}
          widgetMode
          showReadMore={showReadMore}
        />
      )}
    </div>
  );
};

export default PostBlockSocials;
