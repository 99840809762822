import Axios from 'axios';
import {
  EVOLUTION_STEP_FEATURE_PREVIEW,
  EVOLUTION_STEP_RELEASE,
  EVOLUTION_STEP_UP_TO_VOTE,
} from 'constants/evolution';
import {hasFlag} from 'helpers/bitwise';

// Endpoint
const EP_PROJECT_DOMAIN_GET = (domainName) => `/project/domain/${domainName}`;

export const WIDGET_LANG_AUTO = 'AUTO';
export const PORTAL_LANG_AUTO = 'AUTO';
export const F_PAGE_FEED = 1;
export const F_FEED_ANNOUNCEMENT = 2;
export const F_FEED_IDEATION = 4;
export const F_FEED_PREVIEW = 8;
export const F_FEED_SUBSCRIPTION_BOX = 16;
export const F_PAGE_ANNOUNCEMENT = 32;
export const F_PAGE_FEEDBACK = 64;
export const F_FEEDBACK_REQUEST_BOX = 128;
export const F_FEEDBACK_PREVIEW = 256;
export const F_FEEDBACK_IDEATION = 512;
export const F_PAGE_ROADMAP = 1024;
export const F_EXTRA_WIDGET_COLLAPSE_POST = 1;
export const F_EXTRA_WIDGET_MINIMIZING_SIDE_TRIGGER = 2;
export const F_EXTRA_ROADMAP_SHOW = 4;
export const F_EXTRA_ANONYMOUS_FEEDBACK_DISABLED = 128;
export const F_EXTRA_PORTAL_STANDALONE_ENABLED = 32768;
export const F_EXTRA_PREVENT_PERSONAL_DATA_PROMPT = 524288;

export const getProjectByDomainName = (domainName) => {
  return Axios.get(EP_PROJECT_DOMAIN_GET(domainName)).then((response) =>
    response.data != null &&
    response.data.length != null &&
    response.data.length === 0
      ? null
      : response.data
  );
};

export const buildFeedContent = (contentFlags) => {
  const feedContent = [];

  if (hasFlag(F_FEED_ANNOUNCEMENT, contentFlags) === true) {
    feedContent.push(EVOLUTION_STEP_RELEASE);
  }
  if (hasFlag(F_FEED_IDEATION, contentFlags) === true) {
    feedContent.push(EVOLUTION_STEP_UP_TO_VOTE);
  }
  if (hasFlag(F_FEED_PREVIEW, contentFlags) === true) {
    feedContent.push(EVOLUTION_STEP_FEATURE_PREVIEW);
  }
  return feedContent;
};
export const buildFeedbackContent = (contentFlags) => {
  const feedbackContent = [];

  if (hasFlag(F_FEEDBACK_IDEATION, contentFlags) === true) {
    feedbackContent.push(EVOLUTION_STEP_FEATURE_PREVIEW);
  }
  if (hasFlag(F_FEEDBACK_IDEATION, contentFlags) === true) {
    feedbackContent.push(EVOLUTION_STEP_UP_TO_VOTE);
  }
  return feedbackContent;
};
