import classnames from 'classnames';
import React, {useContext} from 'react';
import {BLOCK_INTERVIEW} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const parseInterviewValue = (value) => {
  const [buttonBookText, calendarUrl, buttonCancelText] = value.split(';');

  return {
    buttonBookText,
    calendarUrl,
    buttonCancelText,
  };
};

export const BlockInterview = () => {
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    goToNextStep,
    onBookingOpen,
  } = useContext(PokeStateContext);

  const handleClickBookSlot = (e) => {
    if (inBuilder === true) {
      e.preventDefault();
    }
    onBookingOpen(value.calendarUrl);
    goToNextStep();
  };

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_INTERVIEW,
  });

  const block = currentStep.blocks.find((b) => b.type === BLOCK_INTERVIEW);
  const value = parseInterviewValue(block.value);
  const {
    primaryColor,
    secondaryColor,
    fontPrimaryColor,
    fontSecondaryColor,
    borderPrimaryColor,
    borderSecondaryColor,
    ...restStyle
  } = block.style;

  return (
    <div
      className={classnames('poke-block-interview', {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        selected: selectedBlock === BLOCK_INTERVIEW,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_INTERVIEW);
      }}>
      <div
        className="btn-interview-cancel"
        style={{
          backgroundColor: secondaryColor,
          color: fontSecondaryColor,
          ...(borderSecondaryColor != null
            ? {border: `1px solid ${borderSecondaryColor}`}
            : {}),
          ...restStyle,
        }}
        onClick={() => goToNextStep()}>
        {value.buttonCancelText}
      </div>
      <div
        onClick={handleClickBookSlot}
        rel="noopener noreferrer"
        className="btn-interview-book"
        style={{
          backgroundColor: primaryColor,
          color: fontPrimaryColor,
          ...(borderPrimaryColor != null
            ? {border: `1px solid ${borderPrimaryColor}`}
            : {}),
          ...restStyle,
        }}>
        {value.buttonBookText}
      </div>
      <ClickableBlockOverlay />
    </div>
  );
};
