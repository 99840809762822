import {combineReducers, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/lib/storage';
import generalReducer from '../reducers/general';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  stateReconciler: autoMergeLevel1,
};
const generalPersistReducer = {
  key: 'general',
  storage,
  whitelist: ['projectsJimers', 'tipWelcomeHidden'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    general: persistReducer(generalPersistReducer, generalReducer),
  })
);
let store, persistor;

export default () => {
  return {
    create: () => {
      store = createStore(
        persistedReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          process.env.NODE_ENV !== 'production' &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      );
      persistor = persistStore(store);
      return {store, persistor};
    },
    get: () => ({store, persistor}),
  };
};
