import classnames from 'classnames';
import React, {useContext} from 'react';
import {PostContext} from 'shared/front/components/Post/context';
import './_styles.scss';
import {BLOCK_USER} from 'shared/front/components/Poke/constants/blocks';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const parseUserValue = (value) => {
  const [name, role, avatarUrl] = value.split(';');

  return {name, role, avatarUrl};
};

const PostDetailBlockUser = () => {
  const {post, hideDate} = useContext(PostContext);

  const step = post.steps[0] || {};
  const block = step.blocks.find((b) => b.type === BLOCK_USER);

  if (block == null) {
    return null;
  }

  const value = parseUserValue(block.value);

  return (
    <div className={classnames('post-detail-block-user')}>
      <div
        className="pb-user-avatar"
        style={{
          backgroundImage: `url(${value.avatarUrl})`,
        }}
      />
      <div className="pb-user-infos">
        <strong>{value.name}</strong>
        <span>
          {value.role} •{' '}
          {!hideDate && dayjs(post?.lastStepChangeAt || new Date()).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default PostDetailBlockUser;
