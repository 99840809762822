import Axios from 'axios';
import {Messenger} from 'managers/messenger';
import {generalSelector} from 'selectors';
import {isLocalStorageAvailable} from 'utils/local-stoage';
import store from '../store/configure';

let isSavingAnalyticsEvents = false;

function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

// Endpoint
const EP_ANALYTIC_EVENT_CREATE = '/analytic/event';

const getRecentlySavedEvents = () => {
  const recentlySaved =
    localStorage.getItem('recentlySavedEvents') ?? JSON.stringify([]);
  return JSON.parse(recentlySaved);
};

const updateRecentlySavedEvents = (newEvent) => {
  let recentlySaved = getRecentlySavedEvents();
  const currentTime = new Date().getTime();

  // remove events triggered more than 30 minutes ago
  recentlySaved = recentlySaved.filter(
    (e) =>
      new Date(new Date(e.createdAt).getTime() + 30 * 60000).getTime() >
      currentTime
  );
  recentlySaved = recentlySaved.concat(newEvent);
  localStorage.setItem('recentlySavedEvents', JSON.stringify(recentlySaved));
};

const eventWasRecentlySaved = (newEvent) => {
  let recentlySaved = getRecentlySavedEvents();
  const currentTime = new Date().getTime();

  // remove events triggered more than 30 minutes ago
  recentlySaved = recentlySaved.filter(
    (e) =>
      new Date(new Date(e.createdAt).getTime() + 30 * 60000).getTime() >
      currentTime
  );
  if (
    recentlySaved.find((event) => {
      const {createdAt, ...eventWithoutDate} = event;
      const {createdAt: _, ...newEventWithoutDate} = newEvent;

      return shallowEqual(eventWithoutDate, newEventWithoutDate);
    }) != null
  ) {
    return true;
  } else {
    return false;
  }
};

const getEventsToSave = () => {
  const events = localStorage.getItem('analyticsEvents') ?? JSON.stringify([]);

  return JSON.parse(events);
};

const pushEventsToSave = (newEvent) => {
  const events = getEventsToSave();

  localStorage.setItem(
    'analyticsEvents',
    JSON.stringify(events.concat(newEvent))
  );
};
export const setIsSavingEvents = (value) => {
  isSavingAnalyticsEvents = value;
};
const shiftEventsToSave = () => {
  const events = getEventsToSave();
  const event = events.shift();

  localStorage.setItem('analyticsEvents', JSON.stringify(events));
  return event;
};

const saveEvent = async (data) => {
  const state = store?.()?.get?.()?.store?.getState?.();
  const isPreviewing = state?.general?.isPreviewing;

  if (isPreviewing === true) {
    return;
  }

  const url = generalSelector.getCurrentUrl();
  const newEvent = {...data, createdAt: new Date().toISOString(), url};

  // If boosted, send event to undercity for processing
  if ([EventContext.IN_APP].includes(newEvent.context)) {
    Messenger.sendEvent(newEvent);
    return;
  }

  if (isLocalStorageAvailable() === false) {
    return console.debug(
      "Stormwind/Analytics : Local storage not available, can't save event."
    );
  }

  if (eventWasRecentlySaved(newEvent) !== true) {
    updateRecentlySavedEvents(newEvent);
    pushEventsToSave(newEvent);
  }

  if (isSavingAnalyticsEvents === true) {
    return;
  }
  isSavingAnalyticsEvents = true;
  while (getEventsToSave().length !== 0) {
    const event = shiftEventsToSave();
    const {tries, ...eventWithoutTries} = event;

    try {
      await Axios.post(EP_ANALYTIC_EVENT_CREATE, eventWithoutTries).then(
        (response) => response.data
      );
    } catch (error) {
      if (event.tries < 3) {
        pushEventsToSave({...event, tries: (event.tries || 0) + 1});
      }
    }
  }
  isSavingAnalyticsEvents = false;
};

export const EventType = {
  TOUR_STARTED: 'TOUR_STARTED',
  TOUR_COMPLETED: 'TOUR_COMPLETED',
  TOUR_EXITED: 'TOUR_EXITED',
  TOUR_STEP_SEEN: 'TOUR_STEP_SEEN',
  EVOLUTION_SEEN: 'EVOLUTION_SEEN',
  STEP_SEEN: 'STEP_SEEN',
  CLICK: 'CLICK',
  CTA_CLICK: 'CTA_CLICK',
  DISMISS: 'DISMISS',
};

export const EventContext = {
  IN_APP: 'IN_APP',
  PORTAL_STANDALONE: 'PORTAL_STANDALONE',
  PORTAL_WIDGET: 'PORTAL_WIDGET',
};

export const ClickType = {
  TITLE: 'TITLE',
  CTA: 'CTA',
  BOOSTED: 'BOOSTED',
  INTERCOM: 'INTERCOM',
};

export const ViewType = {
  PORTAL: 'PORTAL',
  WIDGET: 'WIDGET',
  BOOSTED_TOPBAR: 'BOOSTED_TOPBAR',
  BOOSTED_POPIN: 'BOOSTED_POPIN',
  BOOSTED_SNIPPET: 'BOOSTED_SNIPPET',
  BOOSTED_DOT: 'BOOSTED_DOT',
  BOOSTED_TOOLTIP: 'BOOSTED_TOOLTIP',
};

// Methods
export const createClick = ({evolutionId, context}) => {
  return saveEvent({type: EventType.CLICK, evolutionId, context});
};
export const createCtaClick = ({evolutionId, stepId, blockId, context}) => {
  return saveEvent({
    type: EventType.CTA_CLICK,
    evolutionId,
    stepId,
    blockId,
    context,
  });
};
export const createTourStarted = ({evolutionId}) => {
  return saveEvent({
    type: EventType.TOUR_STARTED,
    evolutionId,
    context: EventContext.IN_APP,
  });
};
export const createTourCompleted = ({evolutionId}) => {
  return saveEvent({
    type: EventType.TOUR_COMPLETED,
    evolutionId,
    context: EventContext.IN_APP,
  });
};
export const createTourExited = ({evolutionId, tourStepId}) => {
  return saveEvent({
    type: EventType.TOUR_EXITED,
    evolutionId,
    tourStepId,
    context: EventContext.IN_APP,
  });
};
export const createTourStepSeen = ({evolutionId}) => {
  return saveEvent({
    type: EventType.TOUR_STEP_SEEN,
    evolutionId,
    context: EventContext.IN_APP,
  });
};
export const createEvolutionSeen = ({evolutionId, context}) => {
  return saveEvent({
    type: EventType.EVOLUTION_SEEN,
    evolutionId,
    context,
  });
};
export const createStepSeen = ({evolutionId, stepId, context}) => {
  return saveEvent({
    type: EventType.STEP_SEEN,
    evolutionId,
    stepId,
    context,
  });
};
export const createDismiss = ({evolutionId}) => {
  return saveEvent({
    type: EventType.DISMISS,
    evolutionId,
    context: EventContext.IN_APP,
  });
};
