import classnames from 'classnames';
import fontColorContrast from 'font-color-contrast';
import React, {useContext, useEffect, useRef} from 'react';
import {hasFlag} from '../../../../helpers/bitwise';
import {
  BLOCK_NPS,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_STEPPER,
  getDefaultResponseForBlock,
} from '../../constants/blocks';
import {PokeContext, PokeStateContext} from '../../context';
import {useDisableSubmitOnVote} from '../../hooks/useDisableSubmitOnVote';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {BACKGROUND_TYPE_COLOR} from '../BlockGradient';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const COLOR_NPS_1 = '#f59681';
export const COLOR_NPS_2 = '#f59681';
export const COLOR_NPS_3 = '#e9a99b';
export const COLOR_NPS_4 = '#e9a99b';
export const COLOR_NPS_5 = '#f7d1c2';
export const COLOR_NPS_6 = '#f7d1c2';
export const COLOR_NPS_7 = '#f9e09d';
export const COLOR_NPS_8 = '#f9e09d';
export const COLOR_NPS_9 = '#eaf7cf';
export const COLOR_NPS_10 = '#eaf7cf';

document.documentElement.style.setProperty('--NPS-1', COLOR_NPS_1);
document.documentElement.style.setProperty('--NPS-2', COLOR_NPS_2);
document.documentElement.style.setProperty('--NPS-3', COLOR_NPS_3);
document.documentElement.style.setProperty('--NPS-4', COLOR_NPS_4);
document.documentElement.style.setProperty('--NPS-5', COLOR_NPS_5);
document.documentElement.style.setProperty('--NPS-6', COLOR_NPS_6);
document.documentElement.style.setProperty('--NPS-7', COLOR_NPS_7);
document.documentElement.style.setProperty('--NPS-8', COLOR_NPS_8);
document.documentElement.style.setProperty('--NPS-9', COLOR_NPS_9);
document.documentElement.style.setProperty('--NPS-10', COLOR_NPS_10);

export const NPS_INTERACTIVE_BACKGROUND = 4;

export const parseNPSValue = (value) => {
  const [labelLeft, labelRight] = value.split(';');

  return {
    labelLeft,
    labelRight,
  };
};

export const BlockNPS = () => {
  const {poke} = useContext(PokeContext);
  const {
    currentStep,
    updateState,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    response = getDefaultResponseForBlock(BLOCK_NPS),
    updateResponse,
    submitResponse,
    language,
    addFontFamily,
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_NPS,
  });
  const disableSubmitOnVote = useDisableSubmitOnVote(currentStep);

  const refNpsButtons = [useRef(null)];

  const updateGradient = (npsColor) => {
    updateState({
      gradientStyleOverwrite: [`${npsColor}90`, `${npsColor}90`],
    });
  };

  const block = currentStep.blocks.find((b) => b.type === BLOCK_NPS);
  const style = block.style || {};
  const {
    padding,
    labelsColor: forcedLabelsColor,
    fontFamily = 'Inter',
    ...restStyle
  } = style;
  const labelsColor =
    forcedLabelsColor != null
      ? forcedLabelsColor
      : poke?.style?.background == null
      ? '#FFFFFFFF'
      : poke.style.background.type !== BACKGROUND_TYPE_COLOR
      ? '#FFFFFFFF'
      : poke.style.background.primaryColor;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const {labelLeft, labelRight} = parseNPSValue(block.value);
  const npsButtons = new Array(10).fill(0);
  const npsButtonsColors = [
    COLOR_NPS_1,
    COLOR_NPS_2,
    COLOR_NPS_3,
    COLOR_NPS_4,
    COLOR_NPS_5,
    COLOR_NPS_6,
    COLOR_NPS_7,
    COLOR_NPS_8,
    COLOR_NPS_9,
    COLOR_NPS_10,
  ];

  useEffect(() => {
    if (hasFlag(NPS_INTERACTIVE_BACKGROUND, block.flags) === false) {
      updateState({gradientStyleOverwrite: null});
    }
    return () => updateState({gradientStyleOverwrite: null});
  }, [block?.flags]);

  const handleResponse = (npsIndex) => {
    if (inBuilder === true) {
      return;
    }
    updateResponse(npsIndex);
    if (disableSubmitOnVote === false) {
      submitResponse({response: npsIndex});
    }
  };

  const translation = block.translations?.find((t) => t.language === language);
  const [translatedLabelLeft, translatedLabelRight] = translation?.value?.split(
    ';'
  ) ?? [labelLeft, labelRight];

  const hasCta =
    currentStep.blocks.some((b) => {
      return (
        (b.type === BLOCK_PRIMARY_CTA && b.removed !== true) ||
        (b.type === BLOCK_SECONDARY_CTA && b.removed !== true)
      );
    }) === true;

  const hasStepperBelow =
    hasCta !== true &&
    currentStep.blocks.some((b) => {
      return b.type === BLOCK_STEPPER && b.style.position === 'bottom';
    }) === true;

  return (
    <div
      className={classnames('poke-block-nps', {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        'has-cta': hasCta === true,
        'has-stepper-below': hasStepperBelow === true,
        'is-bottom-block': hasCta !== true && hasStepperBelow !== true,
        selected: selectedBlock === BLOCK_NPS,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_NPS);
      }}>
      <div className="nps-wrapper">
        {npsButtons.map((b, index) => {
          const npsIndex = index + 1;

          return (
            <div
              key={index}
              ref={index === 0 ? refNpsButtons[0] : null}
              className={classnames(`btn-nps btn-nps-${npsIndex}`, {
                active: response === npsIndex,
              })}
              style={{
                padding: `${padding + 8}px ${padding}px`,
                ...restStyle,
              }}
              onMouseOver={() => {
                if (hasFlag(NPS_INTERACTIVE_BACKGROUND, block.flags) === true) {
                  updateGradient(npsButtonsColors[index]);
                }
              }}
              onClick={() => {
                handleResponse(npsIndex);
              }}>
              {npsIndex}
            </div>
          );
        })}
      </div>
      <div
        className={classnames('labels-wrapper', {
          'is-animating-out': playAnimationOut === true,
        })}
        style={{
          fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
        }}>
        <div
          className="label-left"
          style={{
            color:
              forcedLabelsColor != null
                ? labelsColor
                : fontColorContrast(
                    labelsColor.slice(
                      0,
                      labelsColor.length > 7
                        ? labelsColor.length - 2
                        : labelsColor.length
                    )
                  ),
          }}>
          {translatedLabelLeft || labelLeft}
        </div>
        <div
          className="label-right"
          style={{
            color:
              forcedLabelsColor != null
                ? labelsColor
                : fontColorContrast(
                    labelsColor.slice(
                      0,
                      labelsColor.length > 7
                        ? labelsColor.length - 2
                        : labelsColor.length
                    )
                  ),
          }}>
          {translatedLabelRight || labelRight}
        </div>
      </div>
      <ClickableBlockOverlay />
    </div>
  );
};
