export const STEP_TYPE_OPINION_SCALE = 'OPINION_SCALE';
export const STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT =
  'MULTIPLE_CHOICE_SINGLE_SELECT';
export const STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT =
  'MULTIPLE_CHOICE_MULTI_SELECT';
export const STEP_TYPE_TEXT_LONG = 'TEXT_LONG';
export const STEP_TYPE_SLIDER = 'SLIDER';
export const STEP_TYPE_NPS = 'NPS';
export const STEP_TYPE_TEXT_BLOCK = 'TEXT_BLOCK';
export const STEP_TYPE_CONCEPT_TEST = 'CONCEPT_TEST';
export const STEP_TYPE_INTERVIEW = 'INTERVIEW';

export const F_STEP_CTA_IN_NEW_TAB = 1;
