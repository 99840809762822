import classnames from 'classnames';
import Button from 'components/Button';
import CardSubmitRequest from 'components/CardSubmitRequest';
import {Modal} from 'components/Modal';
import React, {useState} from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {generalSelector} from 'selectors';
import './_Styles.scss';

const mapStateToProps = (state) => ({
  project: generalSelector.getProject(state),
});

export default connect(mapStateToProps)((props) => {
  const {project, className} = props;
  const [showModalMakeSuggestion, setShowModalMakeSuggestion] = useState(false);
  const classNames = classnames('btn-widget-make-suggestion', className);

  return (
    <>
      <Button
        primary
        iconRight="icon-send"
        className={classNames}
        onClick={() => setShowModalMakeSuggestion(true)}>
        <Trans i18nKey="others.buttonSubmitRequest"></Trans>
      </Button>
      <Modal
        className="modal-widget-feedback-card-submit"
        isOpen={showModalMakeSuggestion}
        onRequestClose={() => setShowModalMakeSuggestion(false)}>
        <CardSubmitRequest
          title={
            <Trans
              i18nKey="others.buttonSubmitRequestCardTitle"
              values={{projectName: project.name}}></Trans>
          }
          onClose={() => setShowModalMakeSuggestion(false)}></CardSubmitRequest>
      </Modal>
    </>
  );
});
