import {generalActions} from 'actions';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {analyticService} from 'services';
import {object, string, bool} from 'prop-types';
import {EventType} from 'services/analytic';
import {useInView} from 'react-intersection-observer';

const propTypes = {
  evolution: object.isRequired,
  type: string.isRequired,
  context: string.isRequired,
  step: object,
  isFirstTourStep: bool,
  parentTourId: string,
};

const defaultProps = {
  step: null,
  isFirstTourStep: false,
  parentTourId: null,
};

const AnalyticViewTrigger = ({
  evolution,
  type,
  context,
  step,
  isFirstTourStep,
  parentTourId,
}) => {
  const dispatch = useDispatch();

  const viewHistory = useSelector((state) =>
    generalSelector.getAnalyticViewHistory(state)
  );

  const {ref} = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        if (
          viewHistory.find(
            (view) =>
              evolution.uid === view.evolutionId &&
              type === view.type &&
              context === view.context &&
              step?.uid === view.stepId
          ) == null
        ) {
          dispatch(
            generalActions.uptAnalyticViewHistory({
              evolutionId: evolution.uid,
              stepId: step?.uid,
              type,
              context,
            })
          );
          if (type === EventType.TOUR_STEP_SEEN) {
            analyticService.createTourStepSeen({
              evolutionId: evolution.uid,
            });
            if (isFirstTourStep === true) {
              analyticService.createTourStarted({
                evolutionId: parentTourId,
              });
            }
          } else if (type === EventType.EVOLUTION_SEEN) {
            analyticService.createEvolutionSeen({
              evolutionId: evolution.uid,
              context,
            });
          } else if (type === EventType.STEP_SEEN) {
            analyticService.createStepSeen({
              evolutionId: evolution.uid,
              stepId: step.uid,
              context,
            });
          }
        }
      }
    },
  });

  return <span ref={ref} />;
};

AnalyticViewTrigger.propTypes = propTypes;
AnalyticViewTrigger.defaultProps = defaultProps;

export default AnalyticViewTrigger;
