import Axios from 'axios';

// Endpoint
const EP_COMMENT_LIKE_CREATE = (commentId) => `/comment/${commentId}/like`;
const EP_COMMENT_LIKE_DELETE = (commentId, commentLikeId) =>
  `/comment/${commentId}/like/${commentLikeId}`;
const EP_COMMENT_CREATE = '/comment/';

export const createCommentLike = (commentId) => {
  return Axios.post(EP_COMMENT_LIKE_CREATE(commentId)).then(
    (response) => response.data
  );
};

export const deleteCommentLike = (commentId, commentLikeId) => {
  return Axios.delete(EP_COMMENT_LIKE_DELETE(commentId, commentLikeId));
};

export const createComment = (data) => {
  const {message, evolutionId, prototypeId, replyToId} = data;

  return Axios.post(EP_COMMENT_CREATE, {
    message,
    evolutionId,
    prototypeId,
    replyToId,
  }).then((response) => response.data);
};
