import classnames from 'classnames';
import {bool} from 'prop-types';
import React from 'react';
import {Trans} from 'react-i18next';
import {generalSelector} from 'selectors';
import './_styles.scss';

const propTypes = {
  isLight: bool,
};

const defaultProps = {isLight: false};

export const Copyright = () => {
  const project = generalSelector.getProject();

  return (
    <div className="copyright">
      <a
        href={`https://usejimo.com?origin=widget-project-${project.name.replace(
          ' ',
          '-'
        )}-${project.uid}`}
        target="_blank"
        rel="noopener noreferrer">
        <i className="icon-jimo"></i>
        <Trans i18nKey="others.footerPropulsedBy"></Trans> Jimo
      </a>
    </div>
  );
};

const BasicFooter = ({isLight}) => {
  const project = generalSelector.getProject();

  if (project.whiteLabeling === true) {
    return <></>;
  }

  const _getClassName = () => {
    return classnames('footer footer__basic', {'is-light': isLight});
  };

  return <div className={_getClassName()}>{Copyright()}</div>;
};

BasicFooter.propTypes = propTypes;
BasicFooter.defaultProps = defaultProps;

export default BasicFooter;
