import classnames from 'classnames';
import React, {useContext, useEffect} from 'react';
import {PokeContext, PokeStateContext} from '../../context';
import './_styles.scss';

export const BACKGROUND_TYPE_COLOR = 'color';
export const BACKGROUND_TYPE_GRADIENT = 'gradient';

export const BlockGradient = () => {
  const {poke} = useContext(PokeContext);
  const {gradientStyleOverwrite, updateState} = useContext(PokeStateContext);

  const {style} = poke;
  const {background} = style ?? {};
  const {type, animated, primaryColor, secondaryColor} = background ?? {};
  const gradient = gradientStyleOverwrite ?? [primaryColor, secondaryColor];

  useEffect(() => {
    updateState({gradientStyleOverwrite: null});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryColor, secondaryColor]);

  if (type !== 'gradient') {
    return <></>;
  }

  return (
    <>
      {animated === true ? (
        <>
          <div
            className={classnames(
              'poke-block-gradient gradient-big is-animated',
              {}
            )}
            style={{
              backgroundImage: `linear-gradient(${
                animated === true ? '213.36deg' : '180deg'
              }, ${gradient[0]}, transparent)`,
              backgroundColor: gradient[1],
            }}></div>
        </>
      ) : (
        <div
          className={classnames('poke-block-gradient is-classic', {})}
          style={{
            backgroundImage: `linear-gradient(180deg, ${gradient[0]}, ${
              gradient[0].length > 7
                ? `${gradient[0].slice(0, 7)}80`
                : `${gradient[0]}}80)`
            }`,
            backgroundColor: gradient[1],
          }}></div>
      )}
    </>
  );
};
