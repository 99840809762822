export const fontFamiliesOptions = [
  {label: 'Roboto', value: 'Roboto'},
  {label: 'Open Sans', value: 'Open Sans'},
  {label: 'Montserrat', value: 'Montserrat'},
  {label: 'Lato', value: 'Lato'},
  {label: 'Poppins', value: 'Poppins'},
  {label: 'Source Sans Pro', value: 'Source Sans Pro'},
  {label: 'Raleway', value: 'Raleway'},
  {label: 'Inter', value: 'Inter, ui-sans-serif, system-ui, sans-serif'},
  {label: 'Noto Sans', value: 'Noto Sans'},
  {label: 'Playfair Display', value: 'Playfair Display'},
  {label: 'Merriweather', value: 'Merriweather'},
  {label: 'Arial', value: 'Arial, sans-serif'},
  {label: 'Helvetica', value: 'Helvetica, sans-serif'},
  {label: 'Times New Roman', value: 'Times New Roman, serif'},
  {label: 'Times', value: 'Times, serif'},
  {label: 'Courier New', value: 'Courier New, monospace'},
  {label: 'Courier', value: 'Courier, monospace'},
  {label: 'Verdana', value: 'Verdana, sans-serif'},
  {label: 'Georgia', value: 'Georgia, serif'},
  {label: 'Palatino', value: 'Palatino, serif'},
  {label: 'Garamond', value: 'Garamond, serif'},
  {label: 'Bookman', value: 'Bookman, serif'},
  {label: 'Comic Sans MS', value: 'Comic Sans MS, cursive'},
  {label: 'Trebuchet MS', value: 'Trebuchet MS, sans-serif'},
  {label: 'Arial Black', value: 'Arial Black, sans-serif'},
  {label: 'Impact', value: 'Impact, sans-serif'},
  {label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode, sans-serif'},
  {label: 'Tahoma', value: 'Tahoma, sans-serif'},
  {label: 'Lucida Console', value: 'Lucida Console, monospace'},
];

export const nativeFontFamilies = [
  'Arial',
  'Helvetica',
  'Times New Roman, serif',
  'Times, serif',
  'Courier New, monospace',
  'Courier, monospace',
  'Verdana, sans-serif',
  'Georgia, serif',
  'Palatino, serif',
  'Garamond, serif',
  'Bookman, serif',
  'Comic Sans MS, cursive',
  'Trebuchet MS, sans-serif',
  'Arial Black, sans-serif',
  'Impact, sans-serif',
  'Lucida Sans Unicode, sans-serif',
  'Tahoma, sans-serif',
  'Lucida Console, monospace',
];
