import classnames from 'classnames';
import React, {useContext} from 'react';
import {PostContext} from '../../context';
import './_styles.scss';
import {BLOCK_USER} from 'shared/front/components/Poke/constants/blocks';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const parseUserValue = (value) => {
  const [name, role, avatarUrl] = value.split(';');

  return {name, role, avatarUrl};
};

const NAME_TRESHOLD = 18;

const PostBlockUser = ({isCollapsed}) => {
  const {post, hideDate} = useContext(PostContext);

  const step = post.steps[0] || {};

  const block = step.blocks.find((b) => b.type === BLOCK_USER);

  if (block == null) {
    return null;
  }

  const value = parseUserValue(block.value);
  const isExcerpt = value.name.length > NAME_TRESHOLD && isCollapsed;
  const name = isExcerpt
    ? `${value.name.substring(0, NAME_TRESHOLD)}...`
    : isCollapsed
      ? `${value.name}, `
      : value.name;

  return (
    <div
      className={classnames('post-block-user', {
        'is-collapsed': isCollapsed,
      })}
    >
      <div
        className="pb-user-avatar"
        style={{
          backgroundImage: `url(${value.avatarUrl})`,
        }}
      />
      <div className="pb-user-infos">
        <strong>{name}</strong>
        <span>{value.role}</span>
      </div>
      {!hideDate && (
        <div
          className="timestamp"
          title={dayjs(post?.lastStepChangeAt || new Date()).format(
            'HH:mm - DD/MM/YYYY'
          )}
        >
          {isCollapsed
            ? dayjs(post?.lastStepChangeAt || new Date()).fromNow()
            : dayjs(post?.lastStepChangeAt || new Date()).format('DD MMM YYYY')}
        </div>
      )}
    </div>
  );
};

export default PostBlockUser;
