import classnames from 'classnames';
import {useEffect, useState} from 'react';
import './_smiley.scss';

export const AnimationSmiley = () => {
  const [feeling, setFeeling] = useState('normal');

  useEffect(() => {
    setTimeout(() => {
      setFeeling('happy');
    }, 500);
  }, []);

  return (
    <div class={classnames('animation-smiley', feeling)}>
      <div class="eyes">
        <div class="eye"></div>
        <div class="eye"></div>
      </div>
      <div class="mouth"></div>
    </div>
  );
};
