import {
  GENERAL_SET_COUNT_EVOLUTIONS,
  GENERAL_SET_CURRENT_URL,
  GENERAL_SET_DALARAN_PREVIEW_MODE,
  GENERAL_SET_EVOLUTIONS,
  GENERAL_SET_HIDE_TOP_NAV,
  GENERAL_SET_IS_PREVIEWING,
  GENERAL_SET_JIMER,
  GENERAL_SET_PROJECT,
  GENERAL_SET_PROJECT_JIMER,
  GENERAL_SET_TIP_WELCOME_HIDDEN,
  GENERAL_SET_WIDGET_MODE,
  GENERAL_UPT_ANALYTIC_VIEW_HISTORY,
  GENERAL_UPT_EVOLUTION_BY_ID,
  GENERAL_UPT_JIMER,
  GENERAL_UPT_PROJECT,
  GENERAL_SET_PREVIEWED_POST,
} from 'actions/general';

export const defaultCountEvolutionState = {
  total: 0,
  liveForVote: 0,
  featurePreview: 0,
  release: 0,
};

const defaultGeneralState = {
  project: null,
  projectsJimers: {} /** { projectId: {uid, token} } */,
  jimer: null,
  evolutions: [],
  countEvolutions: defaultCountEvolutionState,
  tipWelcomeHidden: false,
  widgetMode: false,
  analyticViewHistory: [],
  currentUrl: null,
  dalaranPreviewMode: false,
  hideTopNav: false,
  isPreviewing: false,
  previewedPost: null,
};

export default (state = defaultGeneralState, action) => {
  switch (action.type) {
    case GENERAL_SET_PROJECT: {
      return {...state, project: action.project};
    }
    case GENERAL_SET_PROJECT_JIMER: {
      return {
        ...state,
        projectsJimers: {
          ...state.projectsJimers,
          [action.project]: action.jimer,
        },
      };
    }
    case GENERAL_SET_JIMER: {
      return {
        ...state,
        jimer: action.jimer,
      };
    }
    case GENERAL_SET_EVOLUTIONS: {
      return {
        ...state,
        evolutions: action.evolutions,
      };
    }
    case GENERAL_SET_COUNT_EVOLUTIONS: {
      return {
        ...state,
        countEvolutions: action.count,
      };
    }
    case GENERAL_UPT_EVOLUTION_BY_ID: {
      return {
        ...state,
        evolutions: state.evolutions.map((e) /** evolution */ =>
          e.uid === action.evolutionId ? {...e, ...action.evolution} : e),
      };
    }
    case GENERAL_UPT_JIMER: {
      return {
        ...state,
        jimer: {
          ...state.jimer,
          ...action.data,
        },
      };
    }
    case GENERAL_SET_TIP_WELCOME_HIDDEN: {
      return {
        ...state,
        tipWelcomeHidden: action.isHidden,
      };
    }
    case GENERAL_SET_WIDGET_MODE: {
      return {
        ...state,
        widgetMode: action.widgetMode,
      };
    }
    case GENERAL_SET_DALARAN_PREVIEW_MODE: {
      return {
        ...state,
        dalaranPreviewMode: action.value,
      };
    }
    case GENERAL_UPT_PROJECT: {
      return {
        ...state,
        project: {...state.project, ...action.data},
      };
    }
    case GENERAL_UPT_ANALYTIC_VIEW_HISTORY: {
      return {
        ...state,
        analyticViewHistory: state.analyticViewHistory.concat(action.data),
      };
    }
    case GENERAL_SET_CURRENT_URL: {
      return {
        ...state,
        currentUrl: action.url,
      };
    }
    case GENERAL_SET_HIDE_TOP_NAV: {
      return {
        ...state,
        hideTopNav: action.value,
      };
    }
    case GENERAL_SET_IS_PREVIEWING: {
      return {
        ...state,
        isPreviewing: action.value,
      };
    }
    case GENERAL_SET_PREVIEWED_POST: {
      return {
        ...state,
        previewedPost: action.post,
      };
    }
    default:
      return state;
  }
};
