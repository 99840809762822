import Axios from 'axios';

// Endpoint
const EP_VOTE_EVOLUTION_NPS = '/vote/evolution/nps';
const EP_VOTE_EVOLUTION_CLAP = '/vote/evolution/clap';
const EP_VOTE_PROTOTYPE_NPS = '/vote/prototype/nps';
const EP_VOTE_DELETE = (voteId) => `/vote/${voteId}`;

export const createEvolutionNpsVote = (data) => {
  const {evolutionId, value} = data;

  return Axios.post(EP_VOTE_EVOLUTION_NPS, {evolutionId, value}).then(
    (response) => response.data
  );
};

export const createEvolutionClapVote = (data) => {
  const {evolutionId, value} = data;

  return Axios.post(EP_VOTE_EVOLUTION_CLAP, {evolutionId, value}).then(
    (response) => response.data
  );
};

export const createPrototypeNpsVote = (data) => {
  const {evolutionId, prototypeId, value} = data;

  return Axios.post(EP_VOTE_PROTOTYPE_NPS, {
    evolutionId,
    prototypeId,
    value,
  }).then((response) => response.data);
};

export const deleteVote = (voteId) => {
  return Axios.delete(EP_VOTE_DELETE(voteId)).then((response) => response.data);
};
