import {
  OPINION_TYPE_EMOJI,
  OPINION_TYPE_SMILEY,
  parseOpinionValue,
} from '../components/BlockOpinion';
import {
  BLOCK_CHOICE,
  BLOCK_NPS,
  BLOCK_OPEN_QUESTION,
  BLOCK_OPINION,
  BLOCK_SLIDER,
  isInteractiveBlock,
} from '../constants/blocks';

export const EVALUATE_DISMISS = 'dismiss';
export const EVALUATE_COMPLETED = 'completed';
export const EVALUATE_CONTINUE = 'continue';

const STEP_CONDITION_ACTION_TYPE_GO_TO_STEP = 'GO_TO_STEP';
const STEP_CONDITION_ACTION_TYPE_DISMISS = 'DISMISS';
const STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE = 'LAUNCH_EXPERIENCE';
const STEP_CONDITION_ACTION_TYPE_SNOOZE = 'SNOOZE';

const operators = {
  EQUAL_TO: 'EQUAL_TO',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO',
  GREATER_THAN: 'GREATER_THAN',
  LESSER_THAN: 'LESSER_THAN',
  CONTAINS: 'CONTAINS',
  DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',
};

export const evaluate = ({steps, step, stepIndex, response}) => {
  const {triggers = []} = step;
  let matchingTrigger = null;
  const blockInteractive = step.blocks.find((b) => isInteractiveBlock(b.type));
  const isLastStep = stepIndex === steps.length - 1;

  if (blockInteractive?.type === BLOCK_CHOICE) {
    matchingTrigger = triggers.find((trigger) => {
      const {conditions = []} = trigger;
      const condition = conditions[0];
      const {operand, value = ''} = condition || {};

      if (operand === 'ALL' || operand == null) {
        return areEqual(
          value.split(';'),
          response.map((r) => r.uid || r)
        );
      }
      if (operand === 'NOT') {
        return !areEqual(
          value.split(';'),
          response.map((r) => r.uid || r)
        );
      }
      if (operand === 'AT_LEAST_ONE_OF') {
        return response.some((v) => value.split(';').includes(v.uid));
      }
      if (operand === 'NONE_OF') {
        return !response.some((v) => value.split(';').includes(v.uid));
      }
      return false;
    });
  }
  if (blockInteractive?.type === BLOCK_OPEN_QUESTION) {
    matchingTrigger = triggers.find((trigger) => {
      const {conditions = []} = trigger;
      const condition = conditions[0];
      const {operand, value = ''} = condition || {};

      if (operand === operators.EQUAL_TO) {
        return value === response;
      }
      if (operand === operators.NOT_EQUAL_TO) {
        return value !== response;
      }
      if (operand === operators.CONTAINS) {
        return response?.includes?.(value) === true;
      }
      if (operand === operators.DOES_NOT_CONTAIN) {
        return response?.includes?.(value) === false;
      }
      return null;
    });
  }
  if (blockInteractive?.type === BLOCK_OPINION) {
    const opinionValue = parseOpinionValue(blockInteractive.value);
    response = response.toString();
    matchingTrigger = triggers.find((trigger) => {
      const {conditions = []} = trigger;
      const condition = conditions[0];
      const {operand, value = ''} = condition || {};

      if (
        [OPINION_TYPE_SMILEY, OPINION_TYPE_EMOJI].includes(opinionValue.type)
      ) {
        if (operand === 'ALL') {
          return value === response.toString();
        }
        if (operand === 'NOT') {
          return value !== response.toString();
        }
        if (operand === 'AT_LEAST_ONE_OF') {
          return (value || '')
            .split(';')
            .map((o) => o)
            .includes(response.toString());
        }
        if (operand === 'NONE_OF') {
          return !(value || '')
            .split(';')
            .map((o) => o)
            .includes(response.toString());
        }
      } else {
        if (operand === operators.EQUAL_TO) {
          return parseInt(value, 10) === parseInt(response, 10);
        }
        if (operand === operators.NOT_EQUAL_TO) {
          return parseInt(value, 10) !== parseInt(response, 10);
        }
        if (operand === operators.GREATER_THAN) {
          return parseInt(value, 10) < parseInt(response, 10);
        }
        if (operand === operators.LESSER_THAN) {
          return parseInt(value, 10) > parseInt(response, 10);
        }
      }
      return null;
    });
  }
  if (blockInteractive?.type === BLOCK_SLIDER) {
    response = response.toString();
    matchingTrigger = triggers.find((trigger) => {
      const {conditions = []} = trigger;
      const condition = conditions[0];
      const {operand, value = ''} = condition || {};

      if (operand === operators.EQUAL_TO) {
        return parseInt(value, 10) === parseInt(response / 10, 10);
      }
      if (operand === operators.NOT_EQUAL_TO) {
        return parseInt(value, 10) !== parseInt(response / 10, 10);
      }
      if (operand === operators.GREATER_THAN) {
        return parseInt(value, 10) < parseInt(response / 10, 10);
      }
      if (operand === operators.LESSER_THAN) {
        return parseInt(value, 10) > parseInt(response / 10, 10);
      }
      return null;
    });
  }
  if (blockInteractive?.type === BLOCK_NPS) {
    matchingTrigger = triggers.find((trigger) => {
      const {conditions = []} = trigger;
      const condition = conditions[0];
      const {operand, value = ''} = condition || {};

      if (operand === operators.EQUAL_TO) {
        return parseInt(value, 10) === parseInt(response, 10);
      }
      if (operand === operators.NOT_EQUAL_TO) {
        return parseInt(value, 10) !== parseInt(response, 10);
      }
      if (operand === operators.GREATER_THAN) {
        return parseInt(value, 10) < parseInt(response, 10);
      }
      if (operand === operators.LESSER_THAN) {
        return parseInt(value, 10) > parseInt(response, 10);
      }
      return null;
    });
  }

  /**
   * Case 1 : No matching trigger and step has endSurvey set to true : we complete the survey
   */
  if (matchingTrigger == null && step?.endSurvey === true) {
    return {state: EVALUATE_COMPLETED};
  }

  /**
   * Case 2 : No Matching trigger and step has goTo set : we continue to the goTo step
   */
  if (matchingTrigger == null && step?.goTo != null) {
    const index = steps.map((s) => s.uid).indexOf(step.goTo?.uid || step.goTo);

    return {
      state: EVALUATE_CONTINUE,
      stepIndex: index,
    };
  }

  /**
   * Case 3 : Matching trigger found
   */
  if (matchingTrigger != null) {
    const {actions} = matchingTrigger;
    const hasDismissAction = actions.some(
      (action) => action.type === STEP_CONDITION_ACTION_TYPE_DISMISS
    );
    const hasGoToStepAction = actions.some(
      (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
    );
    const hasLaunchExperienceAction = actions.some(
      (action) => action.type === STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE
    );
    const hasSnoozeAction = actions.some(
      (action) => action.type === STEP_CONDITION_ACTION_TYPE_SNOOZE
    );

    const filteredActions = actions.filter(
      (action) =>
        action.type !== STEP_CONDITION_ACTION_TYPE_DISMISS &&
        action.type !== STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
    );

    if (hasDismissAction === true || hasSnoozeAction === true) {
      return {state: EVALUATE_DISMISS, actions: filteredActions};
    }
    if (hasLaunchExperienceAction === true) {
      return {state: EVALUATE_COMPLETED, actions: filteredActions};
    }
    if (hasGoToStepAction) {
      const goToStepAction = actions.find(
        (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
      );

      if (goToStepAction.value === 'survey-completed') {
        return {state: EVALUATE_COMPLETED};
      }

      const index =
        goToStepAction.value === 'next-step'
          ? stepIndex + 1
          : steps.map((s) => s.uid).indexOf(goToStepAction.value);

      return {
        state: EVALUATE_CONTINUE,
        stepIndex: index,
        actions: filteredActions,
      };
    }
    return {
      state: EVALUATE_COMPLETED,
      stepIndex: stepIndex + 1,
      actions: filteredActions,
    };
  }

  /**
   * Case 4 : If no matching trigger and goTo is null and it's not the last step, we continue
   * In fact, goTo is null, if the Go to condition of an interactive step is set to the next step.
   * Go to is only set if the Go to condition is set to a specific step which is not the next one.
   */
  if (matchingTrigger == null && step?.goTo == null && isLastStep === false) {
    return {
      state: EVALUATE_CONTINUE,
      stepIndex: stepIndex + 1,
    };
  }

  /**
   * Case 5 : No matching trigger found and no goTo set : we complete the survey
   */
  return {
    state: EVALUATE_COMPLETED,
    stepIndex: stepIndex + 1,
  };
};

export function areEqual(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
}
