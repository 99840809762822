import classnames from 'classnames';
import fontColorContrast from 'font-color-contrast';
import React, {useContext, useEffect} from 'react';
import {hasFlag} from '../../../../helpers/bitwise';
import {BLOCK_MEDIA, BLOCK_STEPPER, BLOCK_USER} from '../../constants/blocks';
import {F_SLOT_HINT} from '../../constants/poke';
import {PokeContext, PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {BACKGROUND_TYPE_COLOR} from '../BlockGradient';
import {MEDIA_POSITION_TOP} from '../BlockMedia';
import {STEPPER_TYPE_LINE} from '../BlockStepper';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const parseUserValue = (value) => {
  const [name, role, avatarUrl] = value.split(';');

  return {name, role, avatarUrl};
};

export const BlockUser = () => {
  const {poke} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    addFontFamily,
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_USER,
  });

  const block = currentStep.blocks.find((b) => b.type === BLOCK_USER);
  const value = parseUserValue(block.value);
  let {
    fontFamily = 'Inter',
    fontColor: forcedFontColor,
    ...restStyle
  } = block.style;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const isTopBlock =
    currentStep.blocks.some((b) => {
      return (
        (b.type === BLOCK_STEPPER &&
          b.style.position === 'top' &&
          b.style.type === STEPPER_TYPE_LINE) ||
        (b.type === BLOCK_MEDIA && b.style.position === MEDIA_POSITION_TOP)
      );
    }) !== true;
  const fontColor =
    forcedFontColor != null
      ? forcedFontColor
      : poke?.style?.background == null
      ? '#FFFFFFFF'
      : poke.style.background.type !== BACKGROUND_TYPE_COLOR
      ? '#FFFFFFFF'
      : poke.style.background.primaryColor;

  const isHint = hasFlag(F_SLOT_HINT, poke.boostFlags);

  return (
    <div
      className={classnames('poke-block-user', {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        selected: selectedBlock === BLOCK_USER,
        'is-top-block': isTopBlock,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_USER);
      }}
      style={{
        fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
      }}>
      <div
        className="pb-user-avatar"
        style={{
          ...restStyle,
          backgroundImage: `url(${value.avatarUrl})`,
        }}></div>
      <div className="pb-user-infos">
        <strong
          style={{
            color:
              forcedFontColor != null
                ? fontColor
                : fontColorContrast(
                    fontColor.slice(
                      0,
                      fontColor.length > 7
                        ? fontColor.length - 2
                        : fontColor.length
                    )
                  ),
          }}>
          {value.name}
        </strong>
        <span
          style={{
            color:
              forcedFontColor != null
                ? fontColor
                : fontColorContrast(
                    fontColor.slice(
                      0,
                      fontColor.length > 7
                        ? fontColor.length - 2
                        : fontColor.length
                    )
                  ),
          }}>
          {value.role}
        </span>
      </div>
      <ClickableBlockOverlay withoutOverflow={isHint} />
    </div>
  );
};
