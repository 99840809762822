import classnames from 'classnames';
import React from 'react';
import './_styles.scss';
import {PostContext} from './context';
import PostBlockBody from './components/PostBlockBody';
import PostBlockLabel from './components/PostBlockLabel';
import PostBlockTitle from './components/PostBlockTitle';
import PostBlockUser from './components/PostBlockUser';
import PostBlockSocials from './components/PostBlockSocials';
import PostBlockCta from './components/PostBlockCta';
import PostBlockThumbnail from './components/PostBlockThumbnail';
import {PostDetail} from './components/PostDetail';

export const F_STEP_BLOCK_CLOSE_DISABLED = 1;
export const F_STEP_HEIGHT_CUSTOM = 2;
export const F_STEP_DISCOVERY_STEP_REQUIRED = 4;
export const F_OPTION_POKE_CARD_WITH_POINTER = 131072;

export const FADE_OUT_DELAY = 500;
export const JIMO_LABEL_HEIGHT = 34;
export const NO_JIMO_LABEL_EXTRA_HEIGHT = 24;

export const Post = ({
  AnalyticViewTrigger = null,
  post,
  language = null,
  theme = null,
  onLaunchPoke = () => {},
  onUrlClick = () => {},
  onImageClick = () => {},
  PostBlockSocialsContent = null,
  enableCollapse = false,
  hideDate = false,
  isDetail = false,
  isFeed = false,
  addFontFamily = () => {},
  ThumbnailWrapper = ({children}) => <>{children}</>,
}) => {
  const {cards} = theme ?? {};
  const {backgroundColor, shadow, borderRadius} = cards ?? {};
  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = `${x}px ${y}px ${blur}px ${color}`;
  const isCollapsed = enableCollapse === true;

  return (
    <PostContext.Provider
      value={{
        post,
        language,
        theme,
        onLaunchPoke,
        onUrlClick,
        onImageClick,
        AnalyticViewTrigger,
        hideDate,
        PostBlockSocialsContent,
        addFontFamily,
        ThumbnailWrapper,
      }}
    >
      {isDetail ? (
        <PostDetail />
      ) : (
        <div
          className={classnames('jimo-post', {
            'is-collapsed': isCollapsed,
            'is-feed': isFeed,
          })}
          style={{
            ...(backgroundColor != null
              ? {backgroundColor: backgroundColor}
              : {}),
            ...(shadow != null
              ? {
                  boxShadow,
                }
              : {}),
            ...(borderRadius != null
              ? {
                  borderRadius: `${borderRadius}px`,
                }
              : {}),
          }}
        >
          {!isFeed && <PostBlockThumbnail />}
          <div className="jimo-post-content">
            {isCollapsed && <PostBlockUser isCollapsed={isCollapsed} />}
            <PostBlockLabel isCollapsed={isCollapsed} />
            <PostBlockTitle isCollapsed={isCollapsed} />
            {isFeed && <PostBlockThumbnail isFeed />}

            {isCollapsed === false && (
              <PostBlockUser isCollapsed={isCollapsed} />
            )}
            <PostBlockBody isCollapsed={isCollapsed} />
            <PostBlockCta />
            <PostBlockSocials showReadMore={isCollapsed} />
          </div>
        </div>
      )}
    </PostContext.Provider>
  );
};
