import React from 'react';
import './_Styles.scss';
import Jimo from './imgs/jimo.svg';
import fontColorContrast from 'font-color-contrast';
import classNames from 'classnames';
import {Trans} from 'react-i18next';
import {generalSelector} from 'selectors';
import {object, bool} from 'prop-types';

const propTypes = {
  background: object,
  isFeed: bool,
};

const defaultProps = {
  background: null,
  isFeed: false,
};

const JimoLabel = ({background, isFeed}) => {
  const project = generalSelector.getProject();
  const colorToUSe =
    background?.type === 'gradient' && isFeed === false
      ? background?.secondaryColor
      : background?.primaryColor;
  const textColor = fontColorContrast(
    colorToUSe != null
      ? colorToUSe.length > 7
        ? colorToUSe.slice(0, colorToUSe.length - 2)
        : colorToUSe
      : '#FFFFFF'
  );

  const theme = textColor === '#000000' ? 'light' : 'dark';

  return (
    <div className={classNames('poke-jimo-label', theme)}>
      <a
        href={`https://usejimo.com?origin=widget-project-${project.name.replace(
          ' ',
          '-'
        )}-${project.uid}`}
        target="_blank"
        rel="noopener noreferrer">
        <span className="poke-label-content">
          <Trans i18nKey="others.footerPropulsedBy" />
          <img src={Jimo} alt="Jimo" className="jimo-logo" />
        </span>
      </a>
    </div>
  );
};

JimoLabel.propTypes = propTypes;
JimoLabel.defaultProps = defaultProps;

export default JimoLabel;
