import classnames from 'classnames';
import dayjs from 'dayjs';
import React, {useContext, useEffect} from 'react';
import {
  BLOCK_LABEL,
  BLOCK_TITLE,
  BLOCK_USER,
} from 'shared/front/components/Poke/constants/blocks';
import relativeTime from 'dayjs/plugin/relativeTime';
import {PostContext} from '../../context';
import './_styles.scss';

dayjs.extend(relativeTime);

const PostBlockTitle = ({isCollapsed}) => {
  const {post, theme, language, hideDate, addFontFamily} =
    useContext(PostContext);

  const step = post.steps[0] || {};

  const block = step.blocks.find((b) => b.type === BLOCK_TITLE);
  const {value} = block;

  const translation = block.translations?.find((t) => t.language === language);

  const showDate = isCollapsed
    ? step?.blocks.find((b) => [BLOCK_USER, BLOCK_LABEL].includes(b.type)) ==
        null && !hideDate
    : step?.blocks.find((b) => [BLOCK_USER].includes(b.type)) == null &&
      !hideDate;

  const {text} = theme ?? {};
  const {titleFontColor, titleFontFamily = 'Inter'} = text ?? {};

  const DateSection = () =>
    showDate === true && (
      <div
        className="timestamp"
        title={dayjs(post?.lastStepChangeAt || new Date()).format(
          'HH:mm - DD/MM/YYYY'
        )}>
        {isCollapsed
          ? dayjs(post?.lastStepChangeAt || new Date()).fromNow()
          : dayjs(post?.lastStepChangeAt || new Date()).format('DD MMM YYYY')}
      </div>
    );

  useEffect(() => {
    addFontFamily(titleFontFamily);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleFontFamily]);

  return (
    <div
      className={classnames('post-block-title', {
        'is-collapsed': isCollapsed,
      })}
      style={{
        ...(titleFontColor != null
          ? {
              color: titleFontColor,
            }
          : {}),
        ...(titleFontFamily != null
          ? {
              fontFamily: `${titleFontFamily}, ui-sans-serif, system-ui, sans-serif`,
            }
          : {}),
      }}>
      {isCollapsed && <DateSection />}
      <span>{translation != null ? translation.value : value}</span>
      {!isCollapsed && <DateSection />}
    </div>
  );
};

export default PostBlockTitle;
