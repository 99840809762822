import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import ar from '../shared/front/translations/ar.json';
import cs from '../shared/front/translations/cs.json';
import fi from '../shared/front/translations/fi.json';
import hu from '../shared/front/translations/hu.json';
import id from '../shared/front/translations/id.json';
import lo from '../shared/front/translations/lo.json';
import pl from '../shared/front/translations/pl.json';
import ru from '../shared/front/translations/ru.json';
import sq from '../shared/front/translations/sq.json';
import sr from '../shared/front/translations/sr.json';
import th from '../shared/front/translations/th.json';
import tr from '../shared/front/translations/tr.json';
import uk from '../shared/front/translations/uk.json';
import vi from '../shared/front/translations/vi.json';
import da from './../shared/front/translations/da.json';
import de from './../shared/front/translations/de.json';
import en from './../shared/front/translations/en.json';
import es from './../shared/front/translations/es.json';
import fr from './../shared/front/translations/fr.json';
import it from './../shared/front/translations/it.json';
import jp from './../shared/front/translations/jp.json';
import nl from './../shared/front/translations/nl.json';
import no from './../shared/front/translations/no.json';
import pt from './../shared/front/translations/pt.json';
import ro from './../shared/front/translations/ro.json';
import se from './../shared/front/translations/se.json';
import sk from './../shared/front/translations/sk.json';
import sv from './../shared/front/translations/sv.json';
import zh from './../shared/front/translations/zh.json';

i18n.use(initReactI18next).init({
  lng: navigator.language.split('-')[0],
  resources: {
    en: {
      translations: en,
    },
    fr: {
      translations: fr,
    },
    es: {
      translations: es,
    },
    pt: {
      translations: pt,
    },
    it: {
      translations: it,
    },
    de: {
      translations: de,
    },
    jp: {
      translations: jp,
    },
    zh: {
      translations: zh,
    },
    sk: {
      translations: sk,
    },
    se: {
      translations: se,
    },
    nl: {
      translations: nl,
    },
    fi: {
      translations: fi,
    },
    ru: {
      translations: ru,
    },
    cs: {
      translations: cs,
    },
    hu: {
      translations: hu,
    },
    pl: {
      translations: pl,
    },
    da: {
      translations: da,
    },
    sv: {
      translations: sv,
    },
    no: {
      translations: no,
    },
    ro: {
      translations: ro,
    },
    sq: {
      translations: sq,
    },
    ar: {
      translations: ar,
    },
    id: {
      translations: id,
    },
    lo: {
      translations: lo,
    },
    sr: {
      translations: sr,
    },
    uk: {
      translations: uk,
    },
    th: {
      translations: th,
    },
    tr: {
      translations: tr,
    },
    vi: {
      translations: vi,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
