export function isLocalStorageAvailable() {
  const foo = 'bar';

  try {
    localStorage.setItem(foo, foo);
    localStorage.removeItem(foo);
    return true;
  } catch (e) {
    return false;
  }
}
