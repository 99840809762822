import React from 'react';
import {connect} from 'react-redux';
import {generalSelector} from 'selectors';
import './_Styles.scss';

const mapStateToProps = (state) => ({
  project: generalSelector.getProject(state),
});

export default connect(mapStateToProps)((props) => {
  const {project} = props;

  return (
    <div className="project-members-avatar">
      {project.members.map((m) => {
        const hasAvatar = m.user != null && m.user.avatarUrl != null;
        const memberClassName =
          hasAvatar === true ? 'member has-avatar' : 'member';
        const memberStyle =
          hasAvatar === true
            ? {backgroundImage: `url(${m.user.avatarUrl})`}
            : {};
        const username = m.user != null ? m.user.username : '?';

        return (
          <div key={m.uid} className={memberClassName} style={memberStyle}>
            {hasAvatar === false && username[0]}
          </div>
        );
      })}
    </div>
  );
});
