import Axios from 'axios';
import JwtDecode from 'jwt-decode';
import {generalSelector} from 'selectors';
import {Swaler} from 'swaler';
const logger = new Swaler('auth.service');

// Endpoint
const EP_AUTH_SIGNUP_JIMER = '/auth/signup/jimer';
const EP_AUTH_SIGNIN_JIMER = '/auth/signin/jimer';
const EP_AUTH_SIGNIN_JIMER_WITH_SESSION = '/auth/signin/jimer-with-session';

export const isAuthenticated = () => {
  const project = generalSelector.getProjectId();
  const timeInSeconds = Math.floor(Date.now() / 1000);
  let token = null;

  if (project == null) {
    logger.debug(`isAuthenticated return false because project is not set`);
    return false;
  }
  token = generalSelector.getProjectJimerToken(project);
  if (!token) {
    logger.debug(
      `isAuthenticated return false because token for project is not set`
    );
    return false;
  }
  token = JwtDecode(token);
  return timeInSeconds < token.exp;
};
export const isIdentified = (jimer = generalSelector.getJimer()) => {
  return jimer.externalEmail != null || jimer.personalEmail != null;
};

export const signUpJimer = async (projectId) => {
  return Axios.post(EP_AUTH_SIGNUP_JIMER, {projectId}).then((response) => ({
    token: response.data.token,
    jimer: response.data.user,
  }));
};

export const signInJimer = async (projectId, jimerId) => {
  return Axios.post(EP_AUTH_SIGNIN_JIMER, {
    projectId,
    jimerId,
  }).then((response) => ({
    token: response.data.token,
    jimer: response.data.user,
  }));
};

export const signInJimerWithSession = async (projectId, sessionToken) => {
  return Axios.post(EP_AUTH_SIGNIN_JIMER_WITH_SESSION, {
    projectId,
    sessionToken,
  }).then((response) => ({
    token: response.data.token,
    jimer: response.data.user,
  }));
};
