import {generalActions} from 'actions';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Router, Switch} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toggle/style.css';
import 'reactjs-popup/dist/index.css';
import {EmptyIntercomLayout, EmptyLayout} from 'router/Layouts';
import {PLACEHOLDER_LIVE_EVOLUTIONS} from 'scenes/Dalaran/Live';
import {generalSelector} from 'selectors';
import {authService, evolutionService} from 'services';
import {Swaler} from 'swaler';
import {
  IntercomPrivateRoute,
  IntercomPublicRoute,
  StandalonePrivateRoute,
  StandalonePublicRoute,
  WidgetDetailPrivateRoute,
  WidgetPrivateRoute,
  WidgetPublicRoute,
} from './router/Routes';
import history from './router/history';
import {
  ROUTE_INTERCOM_ENTER,
  ROUTE_INTERCOM_EVOLUTION_ID,
  ROUTE_INTERCOM_FEED,
  ROUTE_INTERCOM_FEEDBACK,
  ROUTE_STANDALONE_ENTER,
  ROUTE_STANDALONE_EVOLUTION_ID,
  ROUTE_STANDALONE_FEED,
  ROUTE_STANDALONE_FEEDBACK,
  ROUTE_STANDALONE_RELEASE_ID,
  ROUTE_STANDALONE_SETTINGS,
  ROUTE_WIDGET_ENTER,
  ROUTE_WIDGET_EVOLUTION_ID,
  ROUTE_WIDGET_FEED,
  ROUTE_WIDGET_FEEDBACK,
} from './router/routes.const';

import {StormwindPortalLoader} from 'components/StormwindPortalLoader';
import NotFound from 'scenes/NotFound';
import StandaloneEnter from 'scenes/Standalone/Enter';
import StandaloneEvolution from 'scenes/Standalone/Evolution';
import StandaloneFeed from 'scenes/Standalone/Feed';
import StandaloneFeedback from 'scenes/Standalone/Feedback';
import StandaloneSettings from 'scenes/Standalone/Settings';
import WidgetProject from 'scenes/Widget/Enter';
import WidgetEvolution from 'scenes/Widget/Evolution';
import WidgetFeed from 'scenes/Widget/Feed';
import WidgetFeedback from 'scenes/Widget/Feedback';

const logger = new Swaler('Stormwind/Root');

const StormwindPortalWrapper = () => {
  const general = useSelector((state) => state.general);
  const project = useSelector((state) => generalSelector.getProject(state));

  const dispatch = useDispatch();

  const setEvolutions = (evolutions) =>
    dispatch(generalActions.setEvolutions(evolutions));

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const setup = async () => {
      try {
        await initializeData(project);
        setIsLoading(false);
      } catch (err) {
        logger.error(`Could not mount app because of error `, err);
        setIsLoading(false);
        return;
      }
    };

    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @TODO Move this back to app, after boosted are remove from stormwind, to prevent a double loading state that
  const initializeData = async () => {
    // Fetch evolutions
    if (general.dalaranPreviewMode === true) {
      setEvolutions(PLACEHOLDER_LIVE_EVOLUTIONS);
    } else {
      try {
        const evolutions = await evolutionService.getEvolutions({
          relations: [
            'count.comments',
            'count.votes',
            'count.steps.responses',
            'myVote',
            'mySurvey',
            'texts',
            'tags',
            'steps',
            'section',
          ],
        });

        setEvolutions(evolutions);
      } catch (err) {
        logger.error('Fetching evolutions failed with error ', err);
        throw err;
      }
    }
  };

  if (isLoading === true) {
    return (
      <div className="app__loading">
        <StormwindPortalLoader />
      </div>
    );
  }
  if (project == null) {
    return (
      <div className="app__project-not-found fade-in-top">
        <div>
          <div className="title">Ups...</div>It seems that we could not load
          this feedback board currently... Try again later{' '}
          <span role="img" aria-label="ups">
            🤷‍♂️
          </span>
        </div>
      </div>
    );
  }

  return (
    <Router history={history}>
      <Switch>
        {/* STANDALONE */}
        <StandalonePrivateRoute
          exact
          path={ROUTE_STANDALONE_FEEDBACK}
          component={StandaloneFeedback}
          layoutProps={{
            hideBtnSubmitRequest: true,
          }}
        />
        <StandalonePrivateRoute
          exact
          path={ROUTE_STANDALONE_EVOLUTION_ID()}
          layoutProps={{
            hideTopNavOnMobile: true,
            hideBtnSubmitRequest: true,
          }}
          component={StandaloneEvolution}
        />
        <StandalonePrivateRoute
          exact
          path={ROUTE_STANDALONE_RELEASE_ID()}
          layoutProps={{
            hideTopNavOnMobile: true,
            hideBtnSubmitRequest: authService.isIdentified() === false,
            showCardSubscribe: authService.isIdentified() !== true,
          }}
          component={StandaloneEvolution}
        />
        <StandalonePrivateRoute
          exact
          path={ROUTE_STANDALONE_SETTINGS}
          component={StandaloneSettings}
          layoutProps={{
            hideTopNavOnMobile: true,
            hideBtnSubmitRequestOnMobile: true,
          }}
        />
        <StandalonePrivateRoute
          exact
          path={ROUTE_STANDALONE_FEED}
          component={StandaloneFeed}
          layoutProps={{
            hideBtnSubmitRequest: authService.isIdentified() === false,
            showCardSubscribe: true,
          }}
        />
        <StandalonePublicRoute
          exact
          path={ROUTE_STANDALONE_ENTER}
          component={StandaloneEnter}
        />

        {/* WIDGET */}
        <WidgetDetailPrivateRoute
          exact
          component={WidgetEvolution}
          path={ROUTE_WIDGET_EVOLUTION_ID()}
        />
        <WidgetPublicRoute
          exact
          component={WidgetProject}
          layout={EmptyLayout}
          path={ROUTE_WIDGET_ENTER}
        />
        <WidgetPrivateRoute
          exact
          component={WidgetFeed}
          path={[ROUTE_WIDGET_FEED]}
        />
        <WidgetPrivateRoute
          exact
          component={WidgetFeedback}
          path={[ROUTE_WIDGET_FEEDBACK]}
        />

        <IntercomPrivateRoute
          exact
          component={WidgetEvolution}
          path={ROUTE_INTERCOM_EVOLUTION_ID()}
        />
        <IntercomPublicRoute
          exact
          component={WidgetProject}
          layout={EmptyIntercomLayout}
          path={ROUTE_INTERCOM_ENTER}
        />
        <IntercomPrivateRoute
          exact
          component={WidgetFeed}
          path={[ROUTE_INTERCOM_FEED]}
        />
        <IntercomPrivateRoute
          exact
          component={WidgetFeedback}
          path={[ROUTE_INTERCOM_FEEDBACK]}
        />

        {/* 404 */}
        <Route component={NotFound}></Route>
      </Switch>
    </Router>
  );
};

export default StormwindPortalWrapper;
