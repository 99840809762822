import classNames from 'classnames';
import EvolutionComments from 'components/EvolutionComments';
import EvolutionStats from 'components/EvolutionStats';
import FormComment from 'components/FormComment';
import {toastSuccess} from 'components/Toaster';
import i18n from 'conf/i18n';
import {hasFlag} from 'helpers/bitwise';
import {bool, object} from 'prop-types';
import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {generalActions} from 'actions';
import {generalSelector} from 'selectors';
import {F_OPTION_COMMENTS_ENABLED} from 'services/evolution';
import {WIDGET_LANG_AUTO} from 'services/project';
import {Post} from 'shared/front/components/Post';
import './_Styles.scss';
import {Messenger} from 'managers/messenger';
import {motion} from 'framer-motion';
import {AppContext} from 'App';
import Loader from 'components/Loader';
import AnalyticViewTrigger from 'components/AnalyticViewTrigger';
import {EventContext, EventType} from 'services/analytic';

const propTypes = {
  post: object.isRequired,
  standalone: bool,
};

const defaultProps = {
  standalone: false,
};

const PostDetails = ({evolution, standalone, isCommentsLoading}) => {
  const dispatch = useDispatch();
  const project = useSelector((state) => generalSelector.getProject(state));
  const uptEvolutionById = (evolutionId, evolution) =>
    dispatch(generalActions.uptEvolutionById(evolutionId, evolution));

  const {addFontFamily} = useContext(AppContext);

  const handleSubmitComment = () => {
    toastSuccess(
      [<Trans i18nKey="others.commentPostedToastSuccessTitle"></Trans>],
      {
        toastId: 'post-comment-submit',
        className: 'jimo-toast-wrapper-v2 toast--success',
      }
    );
  };

  const hasCommentsEnabled = hasFlag(
    F_OPTION_COMMENTS_ENABLED,
    evolution.optionsFlags
  );

  const theme = project.changelogStyle;

  const handleCommentLiked = (like, commentReply) => {
    let updatedComments = [];

    if (commentReply != null) {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentReply.uid
          ? {
              ...c,
              replies: c.replies.map((r) =>
                r.uid === like.comment.uid
                  ? {...r, likes: r.likes.concat({uid: like.uid}), myLike: like}
                  : r
              ),
            }
          : c);
    } else {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === like.comment.uid
          ? {...c, likes: c.likes.concat({uid: like.uid}), myLike: like}
          : c);
    }
    uptEvolutionById(evolution.uid, {...evolution, comments: updatedComments});
  };
  const handleCommentUnLiked = (commentId, commentLikeId, commentReply) => {
    let updatedComments = [];

    if (commentReply != null) {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentReply.uid
          ? {
              ...c,
              replies: c.replies.map((r) =>
                r.uid === commentId
                  ? {
                      ...r,
                      likes: c.likes.filter((l) => l.uid !== commentLikeId),
                      myLike: null,
                    }
                  : r
              ),
            }
          : c);
    } else {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentId
          ? {
              ...c,
              likes: c.likes.filter((l) => l.uid !== commentLikeId),
              myLike: null,
            }
          : c);
    }
    uptEvolutionById(evolution.uid, {...evolution, comments: updatedComments});
  };
  const handleReplySubmit = (comment, commentReply) => {
    uptEvolutionById(evolution.uid, {
      ...evolution,
      comments: evolution.comments.map((c) =>
        c.uid === commentReply.uid
          ? {...c, replies: c.replies.concat(comment)}
          : c
      ),
    });
    handleSubmitComment();
  };

  return (
    <div
      key={evolution.uid}
      className={classNames('post-details', {
        standalone,
      })}>
      <motion.div
        className="post-scrollable-wrapper"
        initial={{opacity: 0, x: -40}}
        animate={{opacity: 1, x: 0}}
        transition={{
          type: 'spring',
          stiffness: 789,
          damping: 65,
          mass: 1,
        }}>
        <Post
          isDetail
          post={evolution}
          hasCommentsEnabled={true}
          hasLikesEnabled={true}
          language={
            project.widgetLanguage !== WIDGET_LANG_AUTO
              ? project.widgetLanguage
              : i18n.language.toUpperCase()
          }
          theme={theme}
          isPreview={false}
          onLaunchPoke={({pokeId, defaultUrl}) => {
            Messenger.sendLaunchPoke({
              pokeId,
              defaultUrl,
            });
          }}
          onUrlClick={(url) => {
            Messenger.sendOpenCtaUrl(url);
          }}
          onImageClick={(src) => {
            Messenger.sendOpenModalImage(src);
          }}
          PostBlockSocialsContent={() => (
            <EvolutionStats evolution={evolution} widgetMode />
          )}
          addFontFamily={addFontFamily}
          AnalyticViewTrigger={() => {
            return (
              <AnalyticViewTrigger
                evolution={evolution}
                context={EventContext.PORTAL_WIDGET}
                type={EventType.EVOLUTION_SEEN}
              />
            );
          }}
        />
        {hasCommentsEnabled === true && (
          <div
            className={classNames('comments-wrapper', {
              'is-loading': isCommentsLoading,
            })}>
            {isCommentsLoading ? (
              <Loader />
            ) : (
              <EvolutionComments
                evolution={evolution}
                onCommentLiked={handleCommentLiked}
                onCommentUnLiked={handleCommentUnLiked}
                onReplySubmit={handleReplySubmit}
              />
            )}
          </div>
        )}
      </motion.div>

      {hasCommentsEnabled === true && (
        <motion.div
          className={classNames('form-comment-wrapper', {
            'evolution-has-no-comments': evolution.comments?.length === 0,
          })}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 30,
            mass: 1,
          }}>
          <FormComment
            evolution={evolution}
            onSubmitComment={handleSubmitComment}
          />
        </motion.div>
      )}
    </div>
  );
};

PostDetails.propTypes = propTypes;
PostDetails.defaultProps = defaultProps;

export default PostDetails;
