import {useEffect, useState} from 'react';
import {evolutionService} from 'services';
import {REQUEST_POKE, SEND_POKE} from 'services/evolution';
import {Swaler} from 'swaler';

const logger = new Swaler('useFetchPoke');

const SEND_POINTER_DIRECTION = 'SEND_POINTER_DIRECTION';

export const useFetchPoke = ({evolutionId, onPokeFetched = () => {}}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [evolution, setEvolution] = useState(null);
  const [pointerDirection, setPointerDirection] = useState(null);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isLivePreview = params.get('boosted_preview') === 'true';
  const isOutsideBuilder = params.get('outside_builder') === 'true';

  const fetchEvolution = async () => {
    setIsLoading(true);
    const handleMessageReceived = (message) => {
      try {
        const iframeResizerIdentifier = `[iFrameSizer]message:`;
        if (
          message.data.slice(0, iframeResizerIdentifier.length) ===
          iframeResizerIdentifier
        ) {
          const formattedMessage = JSON.parse(
            message.data.substring(iframeResizerIdentifier.length)
          );

          if (formattedMessage?.action === SEND_POINTER_DIRECTION) {
            setPointerDirection(formattedMessage.direction);
          }
        }
      } catch (err) {}
    };

    try {
      const evolution = await evolutionService.getEvolutionById(evolutionId, {
        relations: [
          'myVote',
          'mySurvey',
          'texts',
          'tags',
          'steps',
          'steps.thumbnails',
          'author',
        ],
        ...(isLivePreview === true && isOutsideBuilder === true
          ? {isPreview: true}
          : {}),
      });
      // eslint-disable-next-line no-unused-vars
      const [_, stepsBefore, stepsAfter] = (
        evolution.tourStepInfo ?? '0;0;0'
      ).split(';');
      setEvolution({
        ...evolution,
        stepsBefore: parseInt(stepsBefore, 10),
        stepsAfter: parseInt(stepsAfter, 10),
        steps: evolution?.steps
          ?.sort((a, b) => a.indexOrder - b.indexOrder)
          .map((s) => ({
            ...s,
            prototypes: s?.prototypes?.map((p) => ({
              ...p,
              steps: p.steps?.sort((a, b) => a.indexOrder - b.indexOrder),
            })),
          })),

        prototypes: [],
      });

      onPokeFetched();
    } catch (err) {
      logger.error('Fetching evolution failed with err', err);
    }

    window.addEventListener('message', handleMessageReceived);

    return () => {
      window.removeEventListener('message', handleMessageReceived);
    };
  };

  const setupPreview = () => {
    window.parentIFrame.sendMessage(
      JSON.stringify({action: REQUEST_POKE, evolutionId, fromJimo: true})
    );

    const handleMessageReceived = (message) => {
      try {
        const iframeResizerIdentifier = `[iFrameSizer]message:`;
        if (
          message.data.slice(0, iframeResizerIdentifier.length) ===
          iframeResizerIdentifier
        ) {
          const formattedMessage = JSON.parse(
            message.data.substring(iframeResizerIdentifier.length)
          );

          if (formattedMessage?.action === SEND_POKE) {
            setEvolution(formattedMessage.evolution);
            setPointerDirection(formattedMessage.pointerDirection);
            onPokeFetched();
          }
          if (formattedMessage?.action === SEND_POINTER_DIRECTION) {
            setPointerDirection(formattedMessage.direction);
          }
        }
      } catch (err) {}
    };

    window.addEventListener('message', handleMessageReceived);

    return () => {
      window.removeEventListener('message', handleMessageReceived);
    };
  };

  useEffect(() => {
    // use interval here because couldn't manage to make onReady option work (see: https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/iframed_page/events.md)
    const interval = setInterval(() => {
      if (window.parentIFrame != null) {
        if (isLivePreview && !isOutsideBuilder) {
          setupPreview();
        } else {
          fetchEvolution();
        }
        clearInterval(interval);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    poke: evolution,
    refetch: fetchEvolution,
    pointerDirection,
  };
};
