import AnalyticViewTrigger from 'components/AnalyticViewTrigger';
import {Copyright} from 'components/Footer';
import {useFetchPoke} from 'hooks/useFetchPoke';
import useStormwindMessenger from 'hooks/useStormwindMessenger';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import {generalSelector} from 'selectors';
import {analyticService, surveyService} from 'services';
import {EventContext, EventType} from 'services/analytic';
import {Poke} from 'shared/front/components/Poke';
import {parseConceptValue} from 'shared/front/components/Poke/components/BlockConcept';
import {BLOCK_CONCEPT} from 'shared/front/components/Poke/constants/blocks';
import {PokeContext} from 'shared/front/components/Poke/context';
import {Swaler} from 'swaler';
import i18n from 'i18next';
import './_styles.scss';
import JimoLabel from './JimoLabel';

const logger = new Swaler('Scene/Poke');

const sendUndercityMessage = (message) => {
  sendMessageToParent({
    ...message,
    recipient: 'undercity',
  });
};
const sendMessageToParent = (message) => {
  if ('parentIFrame' in window) {
    window.parentIFrame.sendMessage(
      JSON.stringify({...message, fromJimo: true})
    );
  }
};

export const ScenePoke = () => {
  const match = useRouteMatch();
  const {evolutionId} = match.params;
  const params = new URLSearchParams(window.location.search);

  const {poke, pointerDirection} = useFetchPoke({
    evolutionId,
    onPokeFetched: () => {
      sendUndercityMessage({action: 'loaded', evolutionId});
    },
  });
  const project = useSelector((state) => generalSelector.getProject(state));

  const [inConcept, setInConcept] = useState(false);
  const [builderState, setBuilderState] = useState({});

  const inPreview = params.get('boosted_preview') === 'true';
  const inBuilder = params.get('in_builder') === 'true';
  const inInteractivePreview = params.get('interactive_preview') === 'true';

  const {
    sendBlockSelected,
    sendContainerStyleChange,
    sendPokeHeightChange,
    sendBlockHovered,
    sendRequestBuilderState,
  } = useStormwindMessenger({
    onBuilderStateChange: (state) => {
      setBuilderState(state);
    },
  });

  useEffect(() => {
    if (inBuilder && builderState?.poke == null) {
      sendRequestBuilderState();
    }
  }, [builderState, inBuilder, sendRequestBuilderState]);

  useEffect(() => {
    if (builderState?.inConcept != null) {
      setInConcept(true);
      const [conceptParentStepId] = !!builderState?.inConcept
        ? builderState?.inConcept?.split('|')
        : [];
      const block = poke?.steps
        ?.find((s) => s.uid === conceptParentStepId)
        ?.blocks?.find((b) => b.type === BLOCK_CONCEPT);

      if (block == null) {
        return logger.error(
          "Can't open concept for in-app builder : block concept not found"
        );
      }
      handleConceptOpen(block);
    } else {
      handleConceptClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderState?.inConcept]);

  const handleConceptOpen = (block) => {
    const {conceptUrl} = parseConceptValue(block.value);
    const isFigma = ['figma.com'].some((domain) => conceptUrl.includes(domain));

    if (isFigma === false && conceptUrl != null && inBuilder === false) {
      return window.open(conceptUrl, '_blank');
    }
    sendUndercityMessage({
      action: 'toggle-preview',
      value: true,
      evolutionId,
    });
    setInConcept(true);
  };
  const handleConceptClose = () => {
    setInConcept(false);
    sendUndercityMessage({
      action: 'toggle-preview',
      value: false,
      evolutionId,
    });
  };

  const isTourStep = poke?.parentTourId != null;
  const isFirstTourStep = poke?.stepsBefore === 0;

  const [conceptParentStepId, conceptStepId] = !!builderState?.inConcept
    ? builderState?.inConcept?.split('|')
    : [];
  const currentStepIndex = !!builderState?.inConcept
    ? poke?.steps.findIndex((s) => s.uid === conceptParentStepId)
    : poke?.steps.findIndex((s) => s.uid === builderState?.selectedStepId);
  const currentConceptStepIndex =
    inConcept != null
      ? poke?.steps[currentStepIndex]?.prototypes?.[0]?.steps.findIndex(
          (s) => s.uid === conceptStepId
        )
      : 0;

  return (
    <PokeContext.Provider
      value={{
        poke,
        sendBlockHovered,
        ...(inBuilder === true
          ? {
              currentStepIndex: currentStepIndex >= 0 ? currentStepIndex : 0,
              currentConceptStepIndex,
            }
          : {}),
      }}>
      <div className="s-poke">
        {poke != null && (
          <>
            <Poke
              isIframe
              language={inBuilder === true ? null : i18n.language.toUpperCase()}
              // Disable animations in builder to prevent height calculation issues or if poke has withElementsAnimations set to false
              disableAnimations={
                (inBuilder === true && inInteractivePreview === false) ||
                poke?.style?.withElementsAnimations === false
              }
              inBuilder={inBuilder}
              inAppBuilder={inBuilder}
              inPreview={inPreview}
              inConcept={inConcept}
              iframeListenerEnabled={true}
              JimoLabel={
                project.whiteLabeling === true
                  ? undefined
                  : () => <JimoLabel background={poke?.style?.background} />
              }
              selectedBlock={builderState?.selectedBlockType}
              pointerDirection={pointerDirection}
              AnalyticViewTrigger={({currentStep}) => {
                if (inPreview !== true) {
                  return (
                    <AnalyticViewTrigger
                      key={currentStep.uid}
                      evolution={poke}
                      context={EventContext.IN_APP}
                      type={EventType.STEP_SEEN}
                      step={currentStep}
                    />
                  );
                }
                return null;
              }}
              onClose={({isDismiss, isTourCompleted, isTourExited}) => {
                if (inBuilder === true) {
                  return;
                }
                if (inPreview === false) {
                  // Analytics : consider tour completed or exited
                  Promise.all([
                    ...(isTourExited === true
                      ? [
                          analyticService.createTourExited({
                            evolutionId: poke.parentTourId,
                            tourStepId: poke.uid,
                          }),
                        ]
                      : []),
                    ...(isTourCompleted === true
                      ? [
                          analyticService.createTourCompleted({
                            evolutionId: poke.parentTourId,
                          }),
                        ]
                      : []),
                    ...(isDismiss === true
                      ? [
                          analyticService.createDismiss({
                            evolutionId: poke.uid,
                          }),
                        ]
                      : []),
                  ]);
                }
                if (isTourExited === true) {
                  sendUndercityMessage({
                    action: 'skip',
                    evolutionId: poke.parentTourId,
                  });
                } else {
                  sendUndercityMessage({
                    action: 'close',
                    evolutionId: evolutionId,
                  });
                }
              }}
              onSurveyResponseCreate={async (data) => {
                if (inBuilder === true || inPreview === true) {
                  return;
                }
                return surveyService.createResponse(data);
              }}
              onSurveyCompleted={() => {
                if (inBuilder === true || inPreview === true) {
                  return;
                }
                surveyService.surveyCompleted({
                  evolutionId: poke.uid,
                });
              }}
              onDimensionChange={([width, height]) => {
                // if (inBuilder === true) {
                //   return;
                // }
                // console.log('onDimensionChange : ', height); // keep it for now to debug height
                sendUndercityMessage({
                  action: 'dimension.change',
                  data: [width, height],
                });
              }}
              onBookingOpen={(link) => {
                if (inBuilder === true) {
                  return;
                }
                sendUndercityMessage({
                  action: 'toggle-booking',
                  value: true,
                  link,
                });
              }}
              onConceptOpen={handleConceptOpen}
              onConceptClose={handleConceptClose}
              onWidgetOpen={(evolutionId) => {
                if (inBuilder === true) {
                  return;
                }
                sendUndercityMessage({
                  action: 'open-widget',
                  evolutionId,
                });
              }}
              onCtaClick={({poke, step, block}) => {
                if (inBuilder === true || inPreview === true) {
                  return;
                }
                analyticService.createCtaClick({
                  evolutionId: poke.uid,
                  stepId: step.uid,
                  blockId: block.uid,
                  context: EventContext.IN_APP,
                });
              }}
              /** Only for in-app builder */
              onBlockSelected={(type) => {
                if (inBuilder === false) {
                  return;
                }
                sendBlockSelected(type);
              }}
              onPokeHeightChange={({selectedStepId, height}) => {
                if (inBuilder === false) {
                  return;
                }
                // console.log('onPokeHeightChange : ', height); // keep it for now to debug height
                sendPokeHeightChange(height, selectedStepId);
              }}
              onContainerStyleChange={(style) => {
                if (inBuilder === false) {
                  return;
                }
                sendContainerStyleChange(style);
              }}
              onGoToPreviousPoke={() => {
                sendUndercityMessage({
                  action: 'previous',
                  evolutionId: evolutionId,
                });
              }}
              onGradientStyleOverwriteChange={(gradientStyleOverwrite) => {
                sendUndercityMessage({
                  action: 'gradient-style-overwrite.change',
                  gradientStyleOverwrite,
                });
              }}
              onUrlClick={(url) => {
                sendUndercityMessage({
                  action: 'url.click',
                  url,
                });
              }}
            />
            {inPreview === false && (
              <AnalyticViewTrigger
                evolution={poke}
                context={EventContext.IN_APP}
                type={
                  isTourStep === true
                    ? EventType.TOUR_STEP_SEEN
                    : EventType.EVOLUTION_SEEN
                }
                isFirstTourStep={isFirstTourStep}
                parentTourId={poke.parentTourId}
              />
            )}
          </>
        )}
      </div>
      {inConcept === true && (
        <Helmet>
          <body className="poke-in-concept" />
        </Helmet>
      )}
      <Helmet>
        <body className="poke-mode" />
      </Helmet>
    </PokeContext.Provider>
  );
};
