import CardSubmitRequest from 'components/CardSubmitRequest';
import React from 'react';
import './_Styles.scss';

export const WidgetFeedbackEmptyState = () => {
  return (
    <div className="widget-feedback-empty-state">
      <CardSubmitRequest flat></CardSubmitRequest>
    </div>
  );
};
