import {BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA} from '../constants/blocks';

// If the step has a block CTA,
// we rely on it to submit the response, so voting should not
// submit the response
export const useDisableSubmitOnVote = (step) => {
  return step.blocks.some(
    (b) =>
      [BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA].includes(b.type) &&
      b.removed !== true
  );
};
