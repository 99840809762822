import classnames from 'classnames';
import React, {useContext} from 'react';
import {
  BLOCK_JIMO_LABEL,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_STEPPER,
} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {useBackgroundContrastedColor} from '../../hooks/useBackgroundContrastedColor';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {hex2Rgb} from '../../utils/hex-2-rgb';
import {BlockCta} from '../BlockCta';
import {BlockStepper} from '../BlockStepper';
import './_styles.scss';

const LayoutBottom = ({JimoLabel = null}) => {
  const {currentStep} = useContext(PokeStateContext);
  const contrastedColor = useBackgroundContrastedColor('bottom');
  const playJimoLabelAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_JIMO_LABEL,
  });

  const {style} = currentStep;
  const {background} = style ?? {};

  const shouldShowBlockCta = currentStep.blocks.find(
    (b) =>
      [BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA].includes(b.type) &&
      b.removed !== true
  );
  const blockStepper = currentStep.blocks.find((b) => b.type === BLOCK_STEPPER);

  if (shouldShowBlockCta == null && blockStepper == null && JimoLabel == null) {
    return <></>;
  }

  return (
    <div className="poke-layout-bottom">
      {shouldShowBlockCta != null && (
        <BlockCta
          isBottom={
            !(
              blockStepper != null && blockStepper.style.position === 'bottom'
            ) && JimoLabel == null
          }
        />
      )}
      {blockStepper != null && blockStepper.style.position === 'bottom' && (
        <BlockStepper />
      )}
      {JimoLabel != null && (
        <div
          className={classnames('jimo-label-wrapper', {
            'is-animating-out': playJimoLabelAnimationOut === true,
          })}
          style={{
            borderTopColor: `rgba(${hex2Rgb(contrastedColor).join(',')},${
              contrastedColor === '#000000' ? '0.05' : '0.2'
            })`,
            color: contrastedColor,
          }}>
          <JimoLabel background={background} />
        </div>
      )}
    </div>
  );
};

export default LayoutBottom;
