import classnames from 'classnames';
import React, {useState, useContext} from 'react';
import './_styles.scss';
import {PostContext} from 'shared/front/components/Post/context';
import PostDetailBlockBody from './components/PostDetailBlockBody';
import PostBlockLabel from '../PostBlockLabel';
import PostDetailBlockTitle from './components/PostDetailBlockTitle';
import PostDetailBlockUser from './components/PostDetailBlockUser';
import PostBlockSocials from '../PostBlockSocials';
import PostBlockCta from '../PostBlockCta';
import PostBlockThumbnail from '../PostBlockThumbnail';

export const F_STEP_BLOCK_CLOSE_DISABLED = 1;
export const F_STEP_HEIGHT_CUSTOM = 2;
export const F_STEP_DISCOVERY_STEP_REQUIRED = 4;
export const F_OPTION_POKE_CARD_WITH_POINTER = 131072;

export const FADE_OUT_DELAY = 500;
export const JIMO_LABEL_HEIGHT = 34;
export const NO_JIMO_LABEL_EXTRA_HEIGHT = 24;

export const PostDetail = ({}) => {
  const {theme} = useContext(PostContext);

  const {cards} = theme ?? {};
  const {backgroundColor, shadow, borderRadius} = cards ?? {};
  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = `${x}px ${y}px ${blur}px ${color}`;

  return (
    <div
      className={classnames('jimo-post-detail', {})}
      style={{
        ...(backgroundColor != null ? {backgroundColor: backgroundColor} : {}),
        ...(shadow != null
          ? {
              boxShadow,
            }
          : {}),
        ...(borderRadius != null
          ? {
              borderRadius: `${borderRadius}px`,
            }
          : {}),
      }}
    >
      <PostBlockThumbnail isDetail />
      <div className="jimo-post-detail-content">
        <PostDetailBlockTitle />
        <PostDetailBlockUser />
        <PostBlockLabel />
        <PostDetailBlockBody />
        <PostBlockCta />
        <PostBlockSocials />
      </div>
    </div>
  );
};
