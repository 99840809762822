import React, {useContext, useEffect} from 'react';
import {BLOCK_PRIMARY_CTA} from 'shared/front/components/Poke/constants/blocks';
import {PostContext} from '../../context';
import './_Styles.scss';

export const NAVIGATION_NONE = 'none';
export const NAVIGATION_STEP_NEXT = 'step-next';
export const NAVIGATION_STEP_PREV = 'step-prev';
export const NAVIGATION_CLOSE = 'close';
export const ACTION_URL_OPEN = 'url-open';
export const ACTION_POST_OPEN = 'post-open';
export const ACTION_BOOK_INTERVIEW = 'open-booking-url';

export const parseCtaValue = (value) => {
  const [text, navigation, action, actionData, optionalUrl] = value.split(';');

  return {
    text,
    navigation,
    action,
    actionData, // url | postId
    optionalUrl,
  };
};

const PostBlockCta = ({isBottom}) => {
  const {post, theme, language, onLaunchPoke, addFontFamily} =
    useContext(PostContext);

  const {callToAction} = theme ?? {};
  const {
    backgroundColor,
    borderColor,
    borderRadius,
    fontColor,
    fontFamily = 'Inter',
    align,
  } = callToAction ?? {};

  useEffect(() => {
    addFontFamily(fontFamily);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontFamily]);

  const step = post.steps[0] || {};
  const block = step?.blocks?.find((b) => b.type === BLOCK_PRIMARY_CTA);

  if (block == null) {
    return null;
  }

  const translation = block.translations?.find((t) => t.language === language);

  const value = parseCtaValue(block.value);

  const handleClick = () => {
    if (value.action === ACTION_URL_OPEN) {
      window.open(value.actionData, '_blank');
    } else if (value.action === ACTION_POST_OPEN) {
      onLaunchPoke({pokeId: value.actionData, defaultUrl: value.optionalUrl});
    }
  };

  // default style that only get applied if no theme is set
  const defaultBtnStyle =
    theme != null
      ? {}
      : {
          position: 'relative',
          padding: '12px 24px;',
          borderRadius: '40px',
          boxSizing: 'border-box',
          fontWeight: '500',
          textAlign: 'center',
          transition: 'all 200ms ease',
          display: 'flex',
          outline: 'none',
          border: 'none',
          color: '#ffffff',
          backgroundColor: 'rgb(64, 154, 248)',
          margin: 'auto 0 auto auto',
        };

  return (
    <div
      className="post-block-cta"
      style={{
        ...(backgroundColor != null
          ? {
              backgroundColor,
            }
          : {}),
        ...(borderColor != null
          ? {
              border: `1px solid ${borderColor}`,
            }
          : {}),
        ...(borderRadius != null
          ? {
              borderRadius: `${borderRadius}px`,
            }
          : {}),
        ...(fontColor != null
          ? {
              color: fontColor,
            }
          : {}),
        ...(fontFamily != null
          ? {
              fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
            }
          : {}),
        ...(align != null
          ? align === 'left'
            ? {
                margin: 'auto auto auto 0',
              }
            : align === 'center'
            ? {
                margin: 'auto',
              }
            : align === 'right'
            ? {
                margin: 'auto 0 auto auto',
              }
            : {}
          : {}),
        ...defaultBtnStyle,
      }}
      onClick={(e) => {
        e.stopPropagation();

        handleClick();
      }}>
      {translation != null ? translation.value : value.text}
    </div>
  );
};

export default PostBlockCta;
