import Lottie from 'lottie-react';
import React, {useEffect, useRef} from 'react';
import animation from './animation.json';

const FRAME_NOT_ACTIVE = [1, 2];
const FRAME_ACTIVE = [100, 101];
const FRAME_ACTIVATION_ANIMATION = [18, 100];
const FRAME_DEACTIVATION_ANIMATION = [109, 181];

export const AdvanceIconLike = ({
  active = false,
  style = {},
  size = '52px',
  children,
}) => {
  const lottieRef = useRef(null);
  const initialActive = useRef(null);
  const initialFrame = useRef(
    active === true ? FRAME_ACTIVE : FRAME_NOT_ACTIVE
  );

  useEffect(() => {
    // Let's try to get the animation svg to edit style properties
    if (
      lottieRef.current == null ||
      lottieRef.current.animationContainerRef == null ||
      lottieRef.current.animationContainerRef.current == null ||
      lottieRef.current.animationContainerRef.current.childNodes == null ||
      lottieRef.current.animationContainerRef.current.childNodes.length === 0 ||
      [...lottieRef.current.animationContainerRef.current.childNodes].every(
        (n) => n.nodeName !== 'svg'
      )
    ) {
      console.debug("Couldn't find animation element", lottieRef.current);
      return;
    }
    const svgElement = [
      ...lottieRef.current.animationContainerRef.current.childNodes,
    ].find((n) => n.nodeName === 'svg');

    svgElement.style.width = size;
    svgElement.style.height = size;
    // svgElement.style.transform = 'translate3d(0px, 0px, 0px) scale(3)';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lottieRef]);

  // Let's play the animation when 'active' is changing except when component is mounted
  useEffect(() => {
    if (initialActive.current == null) {
      initialActive.current = active;
      return;
    }
    if (active === true) {
      lottieRef.current.playSegments(FRAME_ACTIVATION_ANIMATION);
    } else {
      lottieRef.current.playSegments(FRAME_DEACTIVATION_ANIMATION);
    }
  }, [active]);

  return (
    <>
      <Lottie
        animationData={animation}
        loop={false}
        initialSegment={initialFrame.current}
        style={{
          position: 'relative',
          display: 'flex',
          ...style,
          ...(active === false ? {filter: 'brightness(0)'} : {}),
        }}
        lottieRef={lottieRef}
      ></Lottie>
      {children}
    </>
  );
};
