import Axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoint
const EP_REQUEST_CREATE = '/request';

export const createRequest = (data) => {
  const projectId = generalSelector.getProjectId();
  const {title, description} = data;

  return Axios.post(EP_REQUEST_CREATE, {
    title,
    description,
    content: description,
    projectId,
  }).then((response) => response.data);
};
