import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import './_styles.scss';

const TextAreaGroup = (props) => {
  const handleKeyDown = (e) => {
    const {onPressEnter = null} = props;

    if (onPressEnter != null) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        onPressEnter();
      }
    }
  };

  const {
    name,
    id,
    placeholder,
    style,
    inputRef,
    required,
    onChange,
    value,
    rows = 1,
    round,
    iconLeft = null,
    ...rest
  } = props;

  const _getClassName = () =>
    classNames('text-area-group', props.intent, {
      disabled: props.disabled,
      large: props.large,
      small: props.small,
      round: props.round,
      'has-icon': props.iconLeft != null,
      'is-typing': props.value !== '',
    });

  return (
    <div className={_getClassName()}>
      {iconLeft != null && value === '' && (
        <i className={`icon-left ${iconLeft}`} />
      )}
      <textarea
        name={name}
        id={id}
        className="input"
        ref={inputRef}
        style={style}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        rows={rows}
        onKeyDown={handleKeyDown}
        {...rest}
      />
    </div>
  );
};

TextAreaGroup.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,
  round: PropTypes.bool,
  style: PropTypes.object,
  inputRef: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextAreaGroup.defaultProps = {
  round: true,
  required: false,
  onChange: () => {},
};

export default TextAreaGroup;
