import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './_styles.scss';

export default class InputGroup extends React.Component {
  _getClassName = () =>
    classNames('input-group', this.props.className, this.props.intent, {
      disabled: this.props.disabled,
      large: this.props.large,
      small: this.props.small,
      round: this.props.round,
      labeled:
        this.props.labelTextLeft != null || this.props.labelTextRight != null,
      'labeled-left': this.props.labelTextLeft != null,
      'labeled-right': this.props.labelTextRight != null,
    });

  handleKeyDown = (e) => {
    const {onPressEnter = null} = this.props;

    if (onPressEnter != null) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        onPressEnter();
      }
    }
  };

  render() {
    const {
      type,
      name,
      id,
      placeholder,
      style,
      inputRef,
      required,
      onChange,
      value,
      className,
      ...rest
    } = this.props;

    return (
      <div className={this._getClassName()}>
        {this.props.labelTextLeft && (
          <div className="label label-left">{this.props.labelTextLeft}</div>
        )}
        <input
          type={type}
          name={name}
          id={id}
          className="input"
          ref={inputRef}
          style={style}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
          onKeyDown={this.handleKeyDown}
          {...rest}
        />
        {this.props.labelTextRight && (
          <div className="label label-right">{this.props.labelTextRight}</div>
        )}
      </div>
    );
  }
}

InputGroup.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,
  round: PropTypes.bool,
  style: PropTypes.object,
  inputRef: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  labelTextLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  labelTextRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
};

InputGroup.defaultProps = {
  type: 'text',
  required: false,
  onChange: () => {},
};
