import Axios from 'axios';
import {generalSelector} from 'selectors';
import {Swaler} from 'swaler';

export const F_EXTRA_FLAG_HAS_SESSION_TOKEN = 2;

const logger = new Swaler('JimerService');

// Endpoints
const EP_JIMER_EXTERNAL_DATA_UPDATE = '/jimer/externaldata';
const EP_JIMER_PERSONAL_DATA_UPDATE = '/jimer/personaldata';
const EP_JIMER_CUSTOM_ATTRIBUTES_UPDATE = '/jimer/custom-attribute';
const EP_JIMER_SESSION_TOKEN_UPDATE = '/jimer/session-token';
// const EP_JIMER_MERGE = '/jimer/merge';

export const hasUsernameGenerated = (username) => {
  const nameSplit = username?.split(' ');

  if (username == null) {
    return false;
  }
  if (nameSplit.length !== 2) {
    return false;
  }
  if (nameSplit[0] !== 'Jimer') {
    return false;
  }
  if (nameSplit[1].length !== 4) {
    return false;
  }
  return true;
};

export const updateJimerExternalData = (
  data,
  jimer = generalSelector.getJimer()
) => {
  const {email, uid, username} = data;

  if (jimer == null) {
    logger.error('Updating jimer external data failed cause jimer is not set!');
    throw new Error('Jimer is not set!');
  }
  return Axios.put(EP_JIMER_EXTERNAL_DATA_UPDATE, {email, uid, username}).then(
    (response) => response.data
  );
};

export const updateJimerPersonalData = (
  data,
  jimer = generalSelector.getJimer()
) => {
  const {email, username, notificationOnEvolutionsDigest} = data;

  if (jimer == null) {
    logger.error('Updating jimer personal data failed cause jimer is not set!');
    throw new Error('Jimer is not set');
  }
  return Axios.put(EP_JIMER_PERSONAL_DATA_UPDATE, {
    email,
    username: username != null ? username : undefined,
    notificationOnEvolutionsDigest,
  }).then((response) => response.data);
};

export const updateJimerCustomAttributes = (data) => {
  return Axios.post(EP_JIMER_CUSTOM_ATTRIBUTES_UPDATE, {attributes: data}).then(
    (response) => response.data
  );
};

export const updateJimerSessionToken = (data) => {
  const {sessionToken} = data;

  return Axios.put(EP_JIMER_SESSION_TOKEN_UPDATE, {sessionToken});
};
