import classnames from 'classnames';
import fontColorContrast from 'font-color-contrast';
import React, {useContext, useEffect, useState} from 'react';
import {
  BLOCK_OPINION,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_STEPPER,
  getDefaultResponseForBlock,
} from '../../constants/blocks';
import {PokeContext, PokeStateContext} from '../../context';
import {useDisableSubmitOnVote} from '../../hooks/useDisableSubmitOnVote';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {BACKGROUND_TYPE_COLOR} from '../BlockGradient';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_star.scss';
import './_styles.scss';

export const OPINION_TYPE_SMILEY = 'smiley';
export const OPINION_TYPE_STAR = 'star';
export const OPINION_TYPE_EMOJI = 'emoji';
export const OPINION_SCALE_3 = '3';
export const OPINION_SCALE_4 = '4';
export const OPINION_SCALE_5 = '5';
export const OPINION_SCALE_6 = '6';
export const OPINION_SCALE_7 = '7';

export const parseOpinionValue = (value) => {
  const [type, scale, labelLeft, labelRight] = value.split(';');

  return {
    type,
    scale: parseInt(scale, 10),
    labelLeft,
    labelRight,
  };
};

export const opinionTypes = {
  [OPINION_TYPE_SMILEY]: {
    [OPINION_SCALE_7]: [
      {label: '😡', value: '1'},
      {label: '🙁', value: '2'},
      {label: '😕', value: '3'},
      {label: '😐', value: '4'},
      {label: '🙂', value: '5'},
      {label: '😀', value: '6'},
      {label: '😍', value: '7'},
    ],
    [OPINION_SCALE_6]: [
      {label: '😡', value: '1'},
      {label: '🙁', value: '2'},
      {label: '😕', value: '3'},
      {label: '🙂', value: '5'},
      {label: '😀', value: '6'},
      {label: '😍', value: '7'},
    ],
    [OPINION_SCALE_5]: [
      {label: '😡', value: '1'},
      {label: '🙁', value: '2'},
      {label: '😐', value: '4'},
      {label: '😀', value: '6'},
      {label: '😍', value: '7'},
    ],
    [OPINION_SCALE_4]: [
      {label: '😡', value: '1'},
      {label: '🙁', value: '2'},
      {label: '😀', value: '6'},
      {label: '😍', value: '7'},
    ],
    [OPINION_SCALE_3]: [
      {label: '😡', value: '1'},
      {label: '😐', value: '4'},
      {label: '😍', value: '7'},
    ],
  },
  [OPINION_TYPE_EMOJI]: {},
  [OPINION_TYPE_STAR]: {},
};

export const BlockOpinion = () => {
  const {poke} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    response = getDefaultResponseForBlock(BLOCK_OPINION),
    updateResponse,
    submitResponse,
    language,
    addFontFamily,
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_OPINION,
  });
  const disableSubmitOnVote = useDisableSubmitOnVote(currentStep);

  const [starHoverIndex, setStarHoverIndex] = useState(-1);

  const block = currentStep.blocks.find((b) => b.type === BLOCK_OPINION);
  const style = block.style || {};
  const {
    padding,
    fontSize,
    labelsColor: forcedLabelsColor,
    fontFamily = 'Inter',
  } = style;
  const labelsColor =
    forcedLabelsColor != null
      ? forcedLabelsColor
      : poke?.style?.background == null
      ? '#FFFFFFFF'
      : poke.style.background.type !== BACKGROUND_TYPE_COLOR
      ? '#FFFFFFFF'
      : poke.style.background.primaryColor;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const value = parseOpinionValue(block.value);
  const [responseValue] =
    typeof response === 'string' ? response?.split(';') : [response];

  const handleResponse = (index) => {
    if (inBuilder === true) {
      return;
    }
    updateResponse(index);
    if (disableSubmitOnVote === false) {
      submitResponse({response: index});
    }
  };

  const translation = block.translations?.find((t) => t.language === language);
  const [translatedLabelLeft, translatedLabelRight] = translation?.value?.split(
    ';'
  ) ?? [value.labelLeft, value.labelRight];

  const hasCta =
    currentStep.blocks.some((b) => {
      return (
        (b.type === BLOCK_PRIMARY_CTA && b.removed !== true) ||
        (b.type === BLOCK_SECONDARY_CTA && b.removed !== true)
      );
    }) === true;

  const hasStepperBelow =
    hasCta !== true &&
    currentStep.blocks.some((b) => {
      return b.type === BLOCK_STEPPER && b.style.position === 'bottom';
    }) === true;

  return (
    <div
      className={classnames('poke-block-opinion', `type-${value.type}`, {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        'has-cta': hasCta === true,
        'has-stepper-below': hasStepperBelow === true,
        'is-bottom-block': hasCta !== true && hasStepperBelow !== true,
        selected: selectedBlock === BLOCK_OPINION,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_OPINION);
      }}>
      <div className="opinion-wrapper" style={{gap: padding}}>
        {[OPINION_TYPE_SMILEY, OPINION_TYPE_EMOJI].includes(value.type) &&
          opinionTypes[value.type][value.scale].map((item) => (
            <div
              key={item.value}
              className={classnames(`opinion-item opinion-${value.type}`, {
                'is-active': responseValue === item.value,
                'is-muted': response.length > 0 && responseValue !== item.value,
              })}
              style={{
                fontSize: fontSize,
                height: fontSize,
                width: fontSize,
                padding: fontSize / 3,
              }}
              onClick={() => handleResponse(item.value)}>
              {item.label}
            </div>
          ))}
        {value.type === OPINION_TYPE_STAR &&
          new Array(value.scale).fill(0).map((s, i) => (
            <Star
              key={i}
              checked={i < starHoverIndex}
              animate={i === starHoverIndex}
              onMouseEnter={() => {
                setStarHoverIndex(() => i);
              }}
              onMouseLeave={() => {
                setStarHoverIndex(() =>
                  responseValue >= 1 ? responseValue - 1 : -1
                );
              }}
              style={{height: fontSize}}
              onClick={() => {
                setStarHoverIndex(i);
                handleResponse(i + 1);
              }}
            />
          ))}
      </div>
      <div
        className="labels-wrapper"
        style={{
          fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
        }}>
        <div
          className="label-left"
          style={{
            color:
              forcedLabelsColor != null
                ? labelsColor
                : fontColorContrast(
                    labelsColor.slice(
                      0,
                      labelsColor.length > 7
                        ? labelsColor.length - 2
                        : labelsColor.length
                    )
                  ),
          }}>
          {translatedLabelLeft}
        </div>
        <div
          className="label-right"
          style={{
            color:
              forcedLabelsColor != null
                ? labelsColor
                : fontColorContrast(
                    labelsColor.slice(
                      0,
                      labelsColor.length > 7
                        ? labelsColor.length - 2
                        : labelsColor.length
                    )
                  ),
          }}>
          {translatedLabelRight}
        </div>
      </div>
      <ClickableBlockOverlay />
    </div>
  );
};

const Star = ({checked, animate, onMouseEnter, onMouseLeave, onClick}) => {
  return (
    <div
      className={classnames('opinion-star', {animate, checked})}
      onMouseEnter={() => {
        onMouseEnter();
      }}
      onMouseLeave={() => {
        onMouseLeave();
      }}
      onClick={() => {
        onClick();
      }}>
      <div className="opinion-start-sparkle">
        <div className="opinion-start-sparkle-line"></div>
        <div className="opinion-start-sparkle-line"></div>
        <div className="opinion-start-sparkle-line"></div>
        <div className="opinion-start-sparkle-line"></div>
        <div className="opinion-start-sparkle-line"></div>
      </div>
      <svg
        className="opinion-start-star"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64">
        <title>Star Icon</title>
        <path d="M36.14,3.09l5.42,17.78H59.66a4.39,4.39,0,0,1,2.62,7.87L47.48,40.14,53,58.3a4.34,4.34,0,0,1-6.77,4.78L32,52l-14.26,11A4.34,4.34,0,0,1,11,58.27l5.55-18.13L1.72,28.75a4.39,4.39,0,0,1,2.62-7.87h18.1L27.86,3.09A4.32,4.32,0,0,1,36.14,3.09Z" />
      </svg>
    </div>
  );
};
