import Axios from 'axios';

// Endpoint
const EP_SURVEY_RESPONSE_CREATE = '/survey/responses';
const EP_SURVEY_COMPLETED = '/survey/completed';

export const createResponse = (data) => {
  const {evolutionId, stepId, value, selectedOptions} = data;

  return Axios.post(EP_SURVEY_RESPONSE_CREATE, {
    evolutionId,
    stepId,
    value,
    selectedOptions,
  }).then((response) => response.data);
};

export const surveyCompleted = (data) => {
  const {evolutionId} = data;

  return Axios.post(EP_SURVEY_COMPLETED, {
    evolutionId,
  }).then((response) => response.data);
};
