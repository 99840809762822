import {useContext} from 'react';
import {PokeContext} from '../context';

export const useContainerStyle = () => {
  const {poke} = useContext(PokeContext);
  const {style} = poke;
  const {shadow, background, overlay, animationIn, animationOut, ...restStyle} =
    style ?? {};
  const {x, y, blur, color} = shadow ?? {};

  return {
    ...restStyle,
    ...(shadow != null && color != null
      ? {
          boxShadow: `${x}px ${y}px ${blur}px ${color}`,
        }
      : {
          boxShadow: 'none',
        }),
  };
};
