import {AppContext} from 'App';
import classnames from 'classnames';
import ProjectMembersAvatar from 'components/ProjectMembersAvatar';
import fontColorContrast from 'font-color-contrast';
import {hasFlag} from 'helpers/bitwise';
import useScroll from 'hooks/useScroll';
import queryString from 'query-string';
import React, {useContext, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {ROUTE_WIDGET_FEED} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {F_OPTION_PORTAL_DISPLAY_FEEDBACK} from 'services/evolution';
import {
  F_FEEDBACK_PREVIEW,
  F_FEEDBACK_REQUEST_BOX,
  F_PAGE_FEED,
  F_PAGE_FEEDBACK,
  F_PAGE_ROADMAP,
} from 'services/project';
import './_Styles.scss';
import ButtonMakeSuggestion from './components/ButtonMakeSuggestion';
import {WidgetFeedbackEmptyState} from './components/EmptyState';

const Feedback = ({isIntercom}) => {
  const {theme} = useContext(AppContext);

  const evolutions = useSelector((state) =>
    generalSelector.getEvolutions(state)
  ).filter((e) => hasFlag(F_OPTION_PORTAL_DISPLAY_FEEDBACK, e.optionsFlags));
  const project = useSelector((state) => generalSelector.getProject(state));

  const [focused, setFocused] = useState(null);
  const [animate, setAnimate] = useState(true);

  const qs = queryString.parse(window.location.search);
  const isPreview = qs.preview === 'true';

  useEffect(() => {
    if (animate === true && focused != null) {
      setAnimate(false);
    }
  }, [animate, focused]);

  const scroll = useScroll();
  const classNames = classnames('widget-feedback', {
    'is-intercom': isIntercom === true,
    'has-announcements-enabled':
      hasFlag(F_PAGE_FEED, project.widgetContentFlags) === true,
    'has-preview-disabled':
      hasFlag(F_FEEDBACK_PREVIEW, project.widgetContentFlags) === false,
    'is-preview': isPreview === true,
  });

  const showRoadmap = hasFlag(F_PAGE_ROADMAP, project.widgetContentFlags);
  const showFeed = hasFlag(F_PAGE_FEED, project.widgetContentFlags);
  const showRequest = hasFlag(
    F_FEEDBACK_REQUEST_BOX,
    project.widgetContentFlags
  );

  const sections = evolutions.reduce((sections, evolution) => {
    if (evolution.section == null) {
      return sections;
    }
    const indexOfSection = sections.findIndex(
      (s) => s.uid === evolution.section.uid
    );

    if (indexOfSection >= 0) {
      sections[indexOfSection].evolutions.push(evolution);
      return sections;
    }
    return sections.concat({...evolution.section, evolutions: [evolution]});
  }, []);

  const {general} = theme ?? {};
  const {background} = general ?? {};
  const {type, primaryColor, secondaryColor} = background ?? {};

  const textColor = fontColorContrast(
    (['shape'].includes(type)
      ? secondaryColor?.slice(0, 7)
      : primaryColor?.slice(0, 7)) ||
      project.widgetThemeColor ||
      '#fff'
  );

  if (hasFlag(F_PAGE_FEEDBACK, project.widgetContentFlags) === false) {
    return <Redirect to={ROUTE_WIDGET_FEED} />;
  }

  return (
    <div className={classNames}>
      {scroll > 290 && evolutions.length !== 0 && showRequest === true && (
        <ButtonMakeSuggestion className="fixed" />
      )}
      <div className="top" style={{color: textColor}}>
        {(showFeed === true || showRoadmap === true) && (
          <div className="header-title">
            {!!project.portalWelcomeTitle ? (
              project.portalWelcomeTitle
            ) : (
              <Trans
                i18nKey="others.widgetFeedbackTopTitle"
                values={{projectName: project.name}}
              />
            )}
          </div>
        )}
        <p>
          {!!project.portalWelcomeMessage ? (
            project.portalWelcomeMessage
          ) : (
            <Trans
              i18nKey="others.widgetFeedbackTopParagraph"
              values={{projectName: project.name}}
            />
          )}
        </p>
        <ProjectMembersAvatar />
      </div>
      <WidgetFeedbackEmptyState />
    </div>
  );
};

export default Feedback;
