/**
 * Send a message to the Stormwind iframe
 */

export const sendUndercityMessage = (message) => {
  sendMessage({
    ...message,
    recipient: 'undercity',
  });
};

export const sendDalaranMessage = (message) => {
  sendMessage({
    ...message,
    recipient: 'dalaran',
  });
};

function sendMessage(message) {
  if (!window.parent) {
    throw new Error('Could not access Undercity view!');
  }
  window.parent.postMessage({...message, fromJimo: true}, '*');
}

/**
 * Data sent in each communication will be structured as a Message
 *
 * @class Message
 */
export class Message {
  constructor(action, data) {
    this.action = action;
    this.data = data;
  }
}
