import classnames from 'classnames';
import fontColorContrast from 'font-color-contrast';
import React, {useContext} from 'react';
import {PokeStateContext} from '../../context';
import {parseConceptValue} from '../BlockConcept';
import {BlockGradient} from '../BlockGradient';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const LayoutConcept = ({children}) => {
  const {
    poke,
    currentConcept,
    playAnimationConceptOut,
    inBuilder,
    closeConcept,
  } = useContext(PokeStateContext);

  const {conceptUrl} = parseConceptValue(currentConcept.value);
  const isFigmaInteractivePreview = conceptUrl.includes('figma.com');
  const figmaEmbedUrl = `https://www.figma.com/embed?embed_host=astra&url=${conceptUrl}`;

  const {style} = poke;
  const {background} = style ?? {};

  const colorToUSe = background?.primaryColor;
  const closeColor = fontColorContrast(
    colorToUSe != null
      ? colorToUSe.length > 7
        ? colorToUSe.slice(0, colorToUSe.length - 2)
        : colorToUSe
      : '#FFFFFF'
  );

  return (
    <div
      className={classnames('poke-layout-concept', {
        'in-builder': inBuilder === true,
        'is-exiting': playAnimationConceptOut === true,
      })}>
      <div className="concept-prototype">
        {isFigmaInteractivePreview === true && (
          <iframe
            src={figmaEmbedUrl}
            frameBorder="0"
            title="iframe_preview_preview"
            className="concept-iframe-figma"></iframe>
        )}
      </div>
      <div
        className={classnames('concept-sidebar', {
          'in-builder': inBuilder === true,
        })}
        style={{
          ...(background?.type === 'color'
            ? {backgroundColor: background?.primaryColor}
            : {}),
        }}>
        {children}
        <BlockGradient />
        {inBuilder === true && <ClickableBlockOverlay isCard />}
      </div>
      <div
        className="concept-close"
        onClick={(e) => {
          if (inBuilder === true) {
            e.stopPropagation();
            e.preventDefault();
            return;
          }
          closeConcept();
        }}
        style={{
          color: closeColor,
        }}>
        <i className="icon-close"></i>
      </div>
    </div>
  );
};
