import classNames from 'classnames';
import colorContrast from 'font-color-contrast';
import {bool} from 'prop-types';
import React, {useContext} from 'react';
import {PokeContext, PokeStateContext} from '../../context';
import './_Styles.scss';

const propTypes = {
  withoutOverflow: bool,
  isNotCardElem: bool,
};

const defaultProps = {
  withoutOverflow: false,
  isNotCardElem: false,
};

const ClickableBlockOverlay = ({
  withoutOverflow,
  isNotCardElem,
  style: propStyle = {},
}) => {
  const {poke, sendBlockHovered} = useContext(PokeContext) ?? {};
  const {inBuilder = false} = useContext(PokeStateContext);

  let contrast = '#000000';

  const {style} = poke ?? {};
  const {background, overlay, margin} = style ?? {};

  if (inBuilder === false && isNotCardElem !== true) {
    return <></>;
  }
  if (isNotCardElem) {
    if (overlay) {
      const alpha = parseInt(overlay.slice(7, 9) || 'FF', 16) / 255;
      contrast = colorContrast(alpha > 0.5 ? '#000000' : '#FFFFFF');
    }
  } else if (poke.style?.background?.type === 'color') {
    contrast = colorContrast(background?.primaryColor?.slice(0, 7));
  }

  return (
    <div
      className={classNames('clickable-block-overlay', {
        'without-overflow': withoutOverflow,
        'is-not-card-elem': isNotCardElem,
      })}
      style={{
        borderColor: contrast,
        ...(margin != null && isNotCardElem === true
          ? {margin: `${margin}px`}
          : {}),
        ...propStyle,
      }}
      onMouseEnter={() => sendBlockHovered?.(true)}
      onMouseLeave={() => sendBlockHovered?.(false)}
    />
  );
};

ClickableBlockOverlay.propTypes = propTypes;
ClickableBlockOverlay.defaultProps = defaultProps;

export default ClickableBlockOverlay;
