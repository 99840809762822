import React from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import './_Styles.scss';
import JimoLabel from 'scenes/Poke/JimoLabel';
import {useContext} from 'react';
import {AppContext} from 'App';

const Footer = () => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const {theme} = useContext(AppContext);
  const {general} = theme ?? {};
  const {background} = general ?? {};

  if (project.whiteLabeling === true) {
    return <></>;
  }
  return (
    <div className="widget-footer">
      <JimoLabel background={background} isFeed />
    </div>
  );
};

export default Footer;
