import {generalActions} from 'actions';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {ROUTE_STANDALONE_ENTER, ROUTE_WIDGET_ENTER} from 'router/routes.const';
import {STEP_TYPE_TEXT_BLOCK} from 'services/steps';

export const PLACEHOLDER_LIVE_EVOLUTIONS = [
  {
    uid: 1,
    title: 'Ideation #1',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum hic necessitatibus illum et aut nisi, assumenda, dolore reiciendis eius quas quo repudiandae cum in ut quasi aperiam totam molestias cupiditate?',
    countComment: 42,
    lastStepChangeAt: '2021-11-20T17:00:00.000Z',
    optionsFlags: 448,
    texts: [],
    votes: [],
    steps: [
      {
        uid: 1,
        question: "We've just released [<new feature>] 🎉",
        mdContent:
          '<p>Excepteur esse laborum proident occaecat ad. Nisi laborum id velit esse dolore enim sit irure do in consectetur cupidatat magna elit. Magna enim nulla elit labore aliqua mollit esse ea cillum.</p>',
        mdRawContent:
          '{"blocks":[{"key":"e99p0","text":"This means you can:","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"8t89j","text":"Value prop #1","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3frpi","text":"Value prop #2","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
        type: STEP_TYPE_TEXT_BLOCK,
      },
    ],
  },
  {
    uid: 2,
    title: 'Ideation #2',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum hic necessitatibus illum et aut nisi, assumenda, dolore reiciendis eius quas quo repudiandae cum in ut quasi aperiam totam molestias cupiditate?',
    countComment: 42,
    lastStepChangeAt: '2021-11-21T17:00:00.000Z',
    optionsFlags: 1,
    texts: [],
    votes: [],
    steps: [
      {
        uid: 1,
        question: "We've just released [<new feature>] 🎉",
        mdContent:
          '<p>Excepteur esse laborum proident occaecat ad. Nisi laborum id velit esse dolore enim sit irure do in consectetur cupidatat magna elit. Magna enim nulla elit labore aliqua mollit esse ea cillum.</p>',
        mdRawContent:
          '{"blocks":[{"key":"e99p0","text":"This means you can:","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"8t89j","text":"Value prop #1","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3frpi","text":"Value prop #2","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
        type: STEP_TYPE_TEXT_BLOCK,
      },
    ],
  },
  {
    uid: 3,
    title: 'Preview #1',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum hic necessitatibus illum et aut nisi, assumenda, dolore reiciendis eius quas quo repudiandae cum in ut quasi aperiam totam molestias cupiditate?',
    countComment: 42,
    lastStepChangeAt: '2021-11-22T17:00:00.000Z',
    optionsFlags: 448,
    texts: [],
    votes: [],
    prototypes: [
      {
        createdAt: '2021-10-29T13:06:53.000Z',
        description: null,
        files: [],
        interactiveLink:
          'https://figma.com/this-is-a-dalaran-liveview-link-that-do-not-work-obv',
        myVote: null,
        name: null,
        playCount: 42,
        uid: 1,
      },
    ],
    steps: [
      {
        uid: 1,
        question: "We've just released [<new feature>] 🎉",
        mdContent:
          '<p>Excepteur esse laborum proident occaecat ad. Nisi laborum id velit esse dolore enim sit irure do in consectetur cupidatat magna elit. Magna enim nulla elit labore aliqua mollit esse ea cillum.</p>',
        mdRawContent:
          '{"blocks":[{"key":"e99p0","text":"This means you can:","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"8t89j","text":"Value prop #1","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3frpi","text":"Value prop #2","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
        type: STEP_TYPE_TEXT_BLOCK,
      },
    ],
  },
  {
    uid: 4,
    title: 'Preview #2',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum hic necessitatibus illum et aut nisi, assumenda, dolore reiciendis eius quas quo repudiandae cum in ut quasi aperiam totam molestias cupiditate?',
    countComment: 42,
    lastStepChangeAt: '2021-11-23T17:00:00.000Z',
    optionsFlags: 448,
    texts: [],
    votes: [],
    prototypes: [
      {
        createdAt: '2021-10-29T13:06:53.000Z',
        description: null,
        files: [],
        interactiveLink:
          'https://figma.com/this-is-a-dalaran-liveview-link-that-do-not-work-obv',
        myVote: null,
        name: null,
        playCount: 42,
        uid: 2,
      },
    ],
    steps: [
      {
        uid: 1,
        question: "We've just released [<new feature>] 🎉",
        mdContent:
          '<p>Excepteur esse laborum proident occaecat ad. Nisi laborum id velit esse dolore enim sit irure do in consectetur cupidatat magna elit. Magna enim nulla elit labore aliqua mollit esse ea cillum.</p>',
        mdRawContent:
          '{"blocks":[{"key":"e99p0","text":"This means you can:","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"8t89j","text":"Value prop #1","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3frpi","text":"Value prop #2","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
        type: STEP_TYPE_TEXT_BLOCK,
      },
    ],
  },
  {
    uid: 5,
    title: 'Announcement #1',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum hic necessitatibus illum et aut nisi, assumenda, dolore reiciendis eius quas quo repudiandae cum in ut quasi aperiam totam molestias cupiditate?',
    countComment: 42,
    lastStepChangeAt: '2021-11-24T17:00:00.000Z',
    optionsFlags: 448,
    texts: [],
    votes: [],
    steps: [
      {
        uid: 1,
        question: "We've just released [<new feature>] 🎉",
        mdContent:
          '<p>Excepteur esse laborum proident occaecat ad. Nisi laborum id velit esse dolore enim sit irure do in consectetur cupidatat magna elit. Magna enim nulla elit labore aliqua mollit esse ea cillum.</p>',
        mdRawContent:
          '{"blocks":[{"key":"e99p0","text":"This means you can:","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"8t89j","text":"Value prop #1","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3frpi","text":"Value prop #2","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
        type: STEP_TYPE_TEXT_BLOCK,
      },
    ],
  },
];

export default (props) => {
  const {match} = props;
  const dispatch = useDispatch();

  dispatch(generalActions.setDalaranPreviewMode(true));

  if (match.params.mode === 'widget') {
    return <Redirect to={ROUTE_WIDGET_ENTER} />;
  }
  return <Redirect to={ROUTE_STANDALONE_ENTER} />;
};
