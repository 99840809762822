import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTE_WIDGET_FEED} from 'router/routes.const';
import './_Styles.scss';

export default function NotFound() {
  return (
    <div className="s-not-found">
      <h2>Oups, we couldn't find this.</h2>
      <p>
        It seems that you have sent to something that doesn't exist anymore.{' '}
        <Link to={ROUTE_WIDGET_FEED}>Go back to home </Link>
      </p>
    </div>
  );
}
