import Confetti from 'canvas-confetti';
import {useEffect, useRef} from 'react';

export const AnimationConfetti = () => {
  const refCanvas = useRef(null);

  useEffect(() => {
    if (refCanvas.current != null) {
      const confetti = Confetti.create(refCanvas.current, {resize: true});

      confetti({
        origin: {y: 0.5},
        particleCount: 50,
        zIndex: 1,
        spread: 100,
        ticks: 500,
        startVelocity: 20,
      });
    }
  }, [refCanvas.current]);

  return (
    <canvas style={{width: '100%', height: '100%'}} ref={refCanvas}></canvas>
  );
};
