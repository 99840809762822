import React, {useContext} from 'react';
import {BLOCK_MEDIA, BLOCK_STEPPER, BLOCK_USER} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {BlockMedia, MEDIA_POSITION_TOP} from '../BlockMedia';
import {BlockStepper, STEPPER_TYPE_LINE} from './../BlockStepper';
import {BlockUser} from './../BlockUser';
import './_styles.scss';

const LayoutTop = () => {
  const {currentStep} = useContext(PokeStateContext);

  const blockMedia = currentStep.blocks.find((b) => b.type === BLOCK_MEDIA);
  const shouldShowBlockUser =
    currentStep.blocks.findIndex((b) => b.type === BLOCK_USER) >= 0;
  const blockStepper = currentStep.blocks.find((b) => b.type === BLOCK_STEPPER);
  const blockStepperValue = blockStepper?.style?.type;

  if (
    blockMedia == null &&
    shouldShowBlockUser === false &&
    blockStepper == null
  ) {
    return <></>;
  }

  return (
    <div className="poke-layout-top">
      {blockMedia != null &&
        blockMedia?.style?.position === MEDIA_POSITION_TOP && <BlockMedia />}
      {/* Stepper : Placed before user when type is line */}
      {blockStepper != null &&
        blockStepper.style.position === 'top' &&
        blockStepperValue === STEPPER_TYPE_LINE && <BlockStepper />}
      {/* User */}
      {shouldShowBlockUser === true && <BlockUser />}
      {/* Stepper : Placed after user for other types */}
      {blockStepper != null &&
        blockStepper.style.position === 'top' &&
        blockStepperValue !== STEPPER_TYPE_LINE && <BlockStepper />}
    </div>
  );
};

export default LayoutTop;
